import React, { useRef } from 'react';
import useListApi from '../../../../../../hooks/useListApi';
import Header from '../../../../../components/Header/Header';
import Heading from '../../../../../components/Heading/Heading';
import MobileHeader from '../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../components/Sidebar/Sidebar';
import Table from '../../../../../components/Table/Table';
import Plus from '../../../../../../images/plus.svg';
import MediaQuery from 'react-responsive';
import ExportIcon from '../../../../../../images/export.svg';

const FeePolicyList = () => {
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('FeeStructure/FeePolicy', false, true);

    const column = [
        {
            heading: 'Name',
            value: 'Name',
        },
        {
            heading: 'Code',
            value: 'Code',
        },
        {
            heading: 'Total Amount',
            value: 'TotalAmount',
        },
        {
            heading: 'Created',
            value: 'CreatedAt',
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'cluster') {
            return (
                <td>
                    <a href={`/registry?CampusId=${row.CampusId}`}>Registry</a>
                </td>
            );
        }
        return rendered;
    };

    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    title="Fee"
                    breadcrumbs="Fee Policies"
                    href="/fee"
                />
                <Heading
                    title="Fee Policies"
                    description="List of all Fee Policies"
                    linktext="Add Fee Policy"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    href="/fee-policy/new"
                    tableRef={tableRef}
                    exporticon={ExportIcon}
                />

                <Table
                    ref={tableRef}
                    shouldView={true}
                    shouldDelete={false}
                    shouldEdit={true}
                    shouldAssignFee={false}
                    checkboxshouldView={true}
                    resource={'Infrastructure/Campus'}
                    refresh={refresh}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    renderColumn={renderColumn}
                    tableHeading="All Fee Policies"
                    exportFileName={'Fee Policies'}
                    name="Fee"
                    multipleDelete="Fees"
                    onEdit={(item) => {
                        window.location.href = `/fee-policy/${item.id}/edit`;
                    }}
                    onView={(item) => {
                        window.location.href = `/fee-policy/${item.id}/view`;
                    }}
                />
            </div>
        </div>
    );
};

export default FeePolicyList;
