import React, { useEffect, useState } from 'react';
import Table from '../../../../../../../components/Table/Table';
import Api from '../../../../../../../../api/api';
import formatNumber from '../../../../../../../../helpers/formatNumber';

const AssignedFeePolicyContent = ({ id, paid, forceRender }) => {
    const [rows, setRows] = useState([]);

    const refresh = () => {};

    useEffect(() => {
        Api.get(`/FeeStructure/ScholarFee/GetEntities/${id}`)
            .then((response) => {
                let soFar = 0;
                let left = paid;
                setRows(
                    response.map((ScholarFee) => {
                        soFar += ScholarFee.PayableAmount;
                        let paidAmount =
                            left > ScholarFee.PayableAmount
                                ? ScholarFee.PayableAmount
                                : left > 0
                                ? left
                                : 0;
                        let out = {
                            MilestoneCode: ScholarFee.Milestone.Code,
                            PolicyFeeAmount: `₹${formatNumber(
                                ScholarFee.PolicyFeeAmount,
                            )}`,
                            PayableAmount: `₹${formatNumber(
                                ScholarFee.PayableAmount,
                            )}`,
                            Discount: `₹${formatNumber(
                                ScholarFee.PolicyFeeAmount -
                                    ScholarFee.PayableAmount,
                            )}`,
                            PaidAmount: `₹${formatNumber(paidAmount)}`,
                            PendingAmount: `₹${formatNumber(
                                ScholarFee.PayableAmount - paidAmount,
                            )}`,
                            Paid: paidAmount === ScholarFee.PayableAmount,
                        };
                        left -= ScholarFee.PayableAmount;
                        return out;
                    }),
                );
            })
            .catch(console.log);
    }, [id, paid, forceRender]);

    const column = [
        {
            heading: 'Deliverable',
            value: 'MilestoneCode',
        },
        {
            heading: 'Amount',
            value: 'PolicyFeeAmount',
        },
        {
            heading: 'Fee Waiver',
            value: 'Discount',
        },
        {
            heading: 'Payable',
            value: 'PayableAmount',
        },
        {
            heading: 'Paid',
            value: 'PaidAmount',
        },
        {
            heading: 'Pending',
            value: 'PendingAmount',
        },
        {
            heading: 'Payment Status',
            render: (item) => {
                let status = item.Paid ? 'Paid' : 'Unpaid';
                if (item.PayableAmount === '₹0') {
                    status = 'Nil';
                }
                return <div className={status}>{status}</div>;
            },
        },
    ];

    // const renderColumn = (row, column, rendered, index) => {
    //     if (column.heading === 'Amount') {
    //         return (
    //             <td className="scholar-assign-fee-view" key={index}>
    //                 {rendered}
    //             </td>
    //         );
    //     } else if (column.heading === 'Discount') {
    //         <td className="scholar-assign-fee-view" key={index}>
    //             {rendered}
    //         </td>;
    //     } else if (column.heading === 'Payable') {
    //         <td className="scholar-assign-fee-view" key={index}>
    //             {rendered}
    //         </td>;
    //     }
    //     return rendered;
    // };

    return (
        <div className="main-wrapper">
            <Table
                checkboxshouldView={false}
                shouldView={false}
                shouldEdit={false}
                shouldDelete={false}
                shouldAssignFee={false}
                resource={'Campus'}
                refresh={refresh}
                data={rows}
                totalRecords={0}
                perPage={'all'}
                setPerPage={() => {}}
                currentPage={1}
                setCurrentPage={() => {}}
                column={column}
                tableHeading="All Deliverables Fee Status"
            />
        </div>
    );
};

export default AssignedFeePolicyContent;
