import React from 'react';
import useFilters from '../../../hooks/useFilters';
import { RenderFormControl } from '../Form/GenericForm';

function RenderFilters({ filters, handleChange, values, className, showAll }) {
    let { filterFields } = useFilters(filters, values, showAll);
    return filterFields.map((field) => {
        return (
            <div
                className="header-filter-input"
                style={{ marginRight: 20, marginTop: 20 }}
            >
                <div>{field.placeholder}</div>
                <RenderFormControl
                    className={className}
                    options={field.options}
                    type={'select'}
                    formErrors={[]}
                    placeholder={field.placeholder}
                    handleChange={handleChange}
                    field={field.field}
                    formValues={values}
                />
            </div>
        );
    });
}

const HeaderFilters = ({
    availableFilter,
    filters,
    setFilters,
    showAll = true,
}) => {
    const handleChangeLocal = (e) => {
        let { name, value } = e.target;

        let f = {
            ...filters,
            [name]: value,
        };
        if (name === 'CampusId') {
            f['ClusterId'] = null;
            f['ResearchCenterId'] = null;
            f['AcademicYearId'] = null;
            f['MilestoneId'] = null;
            f['BatchId'] = null;
            f['ProgramId'] = null;
            f['DisciplineId'] = null;
        }
        setFilters(f);
    };

    return (
        <div className="header-filter" style={{ display: 'flex' }}>
            <RenderFilters
                showAll={showAll}
                filters={availableFilter}
                values={filters}
                handleChange={handleChangeLocal}
                className={'select-drop-down'}
            />
        </div>
    );
};

export default HeaderFilters;
