import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import Header from '../../../../../../../components/Header/Header';
import Heading from '../../../../../../../components/Heading/Heading';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import AssigendFeePolicyAmount from './AssigendFeePolicyAmount';
import AssignedFeePolicyContent from './AssignedFeePolicyContent';
import { useParams } from 'react-router-dom';
import Api from '../../../../../../../../api/api';
import Backarrow from '../../../../../../../../images/back-arrow.svg';

const AssignedFeePolicy = () => {
    const [summary, setSummary] = useState(null);
    const [scholar, setScholar] = useState(null);

    const [forceRender, setForceRender] = useState(0);

    let { id } = useParams();

    const refresh = () => {
        setForceRender((forceRender) => forceRender + 1);
        Api.get(`/Users/Scholar/getEntity/${id}`)
            .then(setScholar)
            .catch(console.log);
    };

    useEffect(() => {
        if (!scholar) {
            return;
        }
        setForceRender((forceRender) => forceRender + 1);
        Api.get(
            `/FeeStructure/Transaction/GetUserPayments?UserId=${scholar.User_id}`,
        )
            .then(setSummary)
            .catch(console.log);
    }, [scholar]);
    useEffect(() => {
        refresh();
    }, [id]);

    if (!summary || !scholar) {
        return (
            <div className="main-wrapper">
                <MediaQuery maxWidth={1023}>
                    <MobileHeader />
                </MediaQuery>
                <MediaQuery minWidth={1024}>
                    <Sidebar />
                </MediaQuery>
                <div className="screen">
                    <div className="heading-button-mobile">
                        <Header
                            homelink="/dashboard"
                            title="Fee"
                            breadcrumbs="Add Fee Policy"
                            href="/fee"
                        />
                        <div> Loading...</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <div className="heading-button-mobile">
                    <Header
                        homelink="/dashboard"
                        title="Assign Fee Policy"
                        breadcrumbs="All Deliverables Fee Status"
                        href="/assign-fee-policy"
                    />
                    <Heading
                        title={`${scholar.FirstName} ${scholar.LastName}`}
                        description={scholar.FeePolicyCode}
                        linktext="Back to Assign Fee Policy"
                        backgroundcolor="none"
                        color="#5D43A5"
                        fontsize="16px"
                        hover="#F9F5FF"
                        className="view-back-button"
                        href="/assign-fee-policy"
                        icon={Backarrow}
                        width="auto"
                        padding="0 20px 0 20px"
                    />
                    <AssigendFeePolicyAmount
                        summary={summary}
                        id={id}
                        scholar={scholar}
                        refresh={refresh}
                    />
                    <AssignedFeePolicyContent
                        forceRender={forceRender}
                        id={id}
                        paid={summary.AmountPaid}
                    />
                </div>
            </div>
        </div>
    );
};

export default AssignedFeePolicy;
