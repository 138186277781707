import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ExportButton } from '../Buttons/ExportButton';
import CustomCheckbox from '../Inputs/CustomCheckbox';
import DeleteModal from '../Modals/DeleteModal';
import Api from '../../../api/api';
import AssignFeePolicy from '../Modals/Fee Modals/Assign Fee Policy/Subcomponents/Assign Fee Policy/AssignFeePolicy';
import Pagination from '../Pagination/Pagination';
import MediaQuery from 'react-responsive';
import Card from '../Cards/Card';
import ErrorModal from '../Modals/Error Modal/ErrorModal';
import renderValue from '../../../helpers/renderValue';

const NoRecordsFound = () => {
    return (
        <div className="no-records-intable">
            <span>No Records Found</span>
        </div>
    );
};
const Loading = () => {
    return (
        <div className="no-records-intable">
            <span>Loading..</span>
        </div>
    );
};

const Table = (
    {
        shouldView = true,
        shouldDelete = true,
        shouldEdit = true,
        editName = 'Edit',
        shouldAssignFee = true,
        shouldAssignDeliverable = false,
        data,
        column,
        renderColumn,
        onView,
        currentPage,
        perPage,
        setPerPage,
        setCurrentPage,
        onEdit,
        totalRecords,
        tableHeading,
        name,
        multipleDelete,
        onDelete,
        refresh,
        resource,
        checkboxshouldView = true,
        filters = [],
        isLoading,
        exportFileName,
        batchDelete = true,
        onAssignDeliverable,
    },
    ref,
) => {
    const [deleteModal, setdeleteModal] = useState(false);
    const [deleteMultiModal, setdeleteMultiModal] = useState(false);
    const deleteModalClose = () => setdeleteModal(false);
    const deleteModalShow = () => setdeleteModal(true);
    const deleteMultiModalClose = () => setdeleteMultiModal(false);
    const deleteMultiModalShow = () => setdeleteMultiModal(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showaction, setShowaction] = useState(false);
    const [errorModal, seterrorModal] = useState('');
    const [errorText, setErrorText] = useState('');
    const [errorText1, setErrorText1] = useState('');
    const [errorText2, setErrorText2] = useState('');
    const errorModalClose = () => seterrorModal(false);
    const errorModalShow = () => seterrorModal(true);
    const [errorMultiModal, seterrorMultiModal] = useState(false);
    const errorMultiModalClose = () => seterrorMultiModal(false);
    const errorMultiModalShow = () => seterrorMultiModal(true);

    useImperativeHandle(ref, () => ({
        exportData: () => {
            return exportTable();
        },
    }));

    const showDrop = () => {
        setShowaction(!showaction);
    };

    const toggleSelect = (item) => {
        let items = [...selectedItems];
        const position = items.indexOf(item);
        if (position > -1) {
            items.splice(position, 1);
        } else {
            items.push(item);
        }
        setSelectedItems(items);
    };

    const toggleSelectAll = () => {
        if (data.length !== selectedItems.length) {
            setSelectedItems([...data]);
        } else {
            setSelectedItems([]);
        }
    };

    const onDeleteLocal = (item) => {
        setSelectedItem(item);
        setErrorText(
            `Are you sure you want to delete this ${
                name ? name : 'record'
            } ? This action cannot be undone.`,
        );
        deleteModalShow();
    };

    function getItemId(item) {
        return item.Id || item.id;
    }

    const onDeleteSelected = () => {
        deleteMultiModalShow();
    };
    const onActuallyDeleteSelected = () => {
        let allPromises = selectedItems.map((item) => {
            return Api.delete(`/${resource}/deleteEntity/${getItemId(item)}`);
        });

        deleteMultiModalClose();

        Promise.allSettled(allPromises).then((response) => {
            let failed = response.filter(({ status }) => status === 'rejected');
            if (failed.length > 0) {
                let message = failed.map(({ reason }) => {
                    return reason.Message;
                });

                deleteMultiModalClose();
                setErrorText2(
                    `${
                        failed.length
                    } records were not deleted. Error: ${message.join('\n')}`,
                );
                errorMultiModalShow();
            }
            setSelectedItems([]);
            refresh();
            //errorMultiModalClose();
        });
    };

    const onActuallyDelete = () => {
        if (onDelete) {
            onDelete(selectedItem);
            return;
        }
        Api.delete(`/${resource}/deleteEntity/${getItemId(selectedItem)}`)
            .then((response) => {
                deleteModalClose();
                refresh();
            })
            .catch((e) => {
                deleteModalClose();
                setErrorText1(JSON.stringify(e.Message));
                errorModalShow();
            })
            .finally(() => {});
    };

    const exportTable = () => {
        return getData();
    };

    const getData = () => {
        let d = [
            column.map((col) => {
                return col.heading;
            }),
        ];
        if (data) {
            d = [
                ...d,
                ...data.map((row) => {
                    return column.map((col) => {
                        return renderValue(row, col);
                    });
                }),
            ];
        }
        return d;
    };

    const shouldShowActions =
        shouldEdit ||
        shouldView ||
        shouldDelete ||
        shouldAssignFee ||
        shouldAssignDeliverable;

    return (
        <>
            {' '}
            <MediaQuery minWidth={1024}>
                <div className="table-dash">
                    <div className="table-content">
                        <div className="delete-row ">
                            <span>{tableHeading}</span>
                            {selectedItems.length > 0 && batchDelete !== false && (
                                <div className="delete-row-button">
                                    <ExportButton
                                        width="auto"
                                        onClick={onDeleteSelected}
                                        height="36px"
                                        hover="#FEF9F9"
                                        border="1px solid #F04438"
                                        color="#F04438"
                                        padding="0px 10px"
                                        borderradius="6px"
                                    >
                                        Delete
                                    </ExportButton>
                                </div>
                            )}
                        </div>

                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="table-checkbox">
                                            {checkboxshouldView && (
                                                <CustomCheckbox
                                                    isChecked={
                                                        data.length ===
                                                            selectedItems.length &&
                                                        data.length > 0
                                                    }
                                                    onChange={toggleSelectAll}
                                                />
                                            )}
                                            {column.map((item, index) => (
                                                <>
                                                    {index === 0 && (
                                                        <div className="table-name-heading">
                                                            {item.heading}
                                                        </div>
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    </th>
                                    {column.map((item, index) => (
                                        <>
                                            {index !== 0 && (
                                                <TableHeadItems item={item} />
                                            )}
                                        </>
                                    ))}
                                    {shouldShowActions && (
                                        <th className="action-th">Actions</th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {!isLoading &&
                                    data &&
                                    data.map((item, index) => (
                                        <TableRow
                                            shouldShowActions={
                                                shouldShowActions
                                            }
                                            editName={editName}
                                            onEdit={onEdit}
                                            toggleSelect={toggleSelect}
                                            selectedItems={selectedItems}
                                            item={item}
                                            column={column}
                                            shouldView={shouldView}
                                            shouldDelete={shouldDelete}
                                            shouldEdit={shouldEdit}
                                            shouldAssignFee={shouldAssignFee}
                                            shouldAssignDeliverable={
                                                shouldAssignDeliverable
                                            }
                                            onAssignDeliverable={
                                                onAssignDeliverable
                                            }
                                            onDelete={onDeleteLocal}
                                            renderColumn={renderColumn}
                                            onView={onView}
                                            checkboxshouldView={
                                                checkboxshouldView
                                            }
                                            showDrop={showDrop}
                                            showaction={showaction}
                                        />
                                    ))}
                            </tbody>
                        </table>
                        {(!data || data.length === 0) && !isLoading && (
                            <NoRecordsFound />
                        )}
                        {isLoading && <Loading />}
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1023}>
                <Card
                    column={column}
                    data={data}
                    renderColumn={renderColumn}
                    onDelete={onDeleteLocal}
                    onEdit={onEdit}
                    editName={editName}
                    onView={onView}
                    shouldDelete={shouldDelete}
                    shouldView={shouldView}
                    shouldEdit={shouldEdit}
                    shouldAssignDeliverable={shouldAssignDeliverable}
                />
            </MediaQuery>
            <Modal
                show={deleteModal}
                onHide={deleteModalClose}
                centered
                className="deleteModal"
            >
                <DeleteModal
                    heading={`You are about to delete a ${name}`}
                    errorText={errorText}
                    onClose={deleteModalClose}
                    onDelete={onActuallyDelete}
                />
            </Modal>
            <Modal
                show={deleteMultiModal}
                onHide={deleteMultiModalClose}
                centered
                className="deleteModal"
            >
                <DeleteModal
                    isMulti
                    heading={`You are about to delete ${multipleDelete}`}
                    onClose={deleteMultiModalClose}
                    onDelete={onActuallyDeleteSelected}
                />
            </Modal>
            <Modal
                show={errorModal}
                onHide={errorModalClose}
                centered
                className="errorModal"
            >
                <ErrorModal
                    errorText={errorText1}
                    onClose={errorModalClose}
                    onClick={errorModalClose}
                />
            </Modal>
            <Modal
                show={errorMultiModal}
                onHide={errorMultiModalClose}
                centered
                className="errorModal"
            >
                <ErrorModal
                    isMulti
                    errorText={errorText2}
                    onClose={errorMultiModalClose}
                    onClick={errorMultiModalClose}
                />
            </Modal>
            <Pagination
                exportFileName={exportFileName || tableHeading}
                exportTable={getData}
                onPageChange={setCurrentPage}
                setPerPage={setPerPage}
                totalRecords={totalRecords}
                currentPage={currentPage}
                pageLimit={perPage}
            />
        </>
    );
};

const TableHeadItems = ({ item }) => <th>{item.heading}</th>;
const TableRow = ({
    item,
    column,
    renderColumn,
    onView,
    onDelete,
    selectedItems,
    toggleSelect,
    checkboxshouldView,
    editName,
    onEdit,
    shouldView,
    shouldDelete,
    shouldEdit,
    shouldAssignFee,
    shouldShowActions,
    shouldAssignDeliverable,
    onAssignDeliverable,
}) => {
    const [assignModal, setassignModal] = useState(false);
    const assignModalClose = () => setassignModal(false);
    const assignModalShow = () => setassignModal(true);

    const onAssignShowModal = () => {
        assignModalShow();
    };

    const onAssignCloseModal = () => {
        assignModalClose();
    };

    return (
        <tr>
            <td>
                <div className="table-checkbox">
                    {checkboxshouldView && (
                        <CustomCheckbox
                            isChecked={selectedItems.indexOf(item) > -1}
                            onChange={() => {
                                toggleSelect(item);
                            }}
                        />
                    )}
                    {column.map((columnItem, index) => {
                        if (index === 0) {
                            const rendered = (
                                <div key={index}>
                                    {renderValue(item, columnItem)}
                                </div>
                            );
                            if (renderColumn) {
                                return renderColumn(item, columnItem, rendered);
                            }
                            return rendered;
                        }
                    })}
                </div>
            </td>
            {column.map((columnItem, index) => {
                if (index !== 0) {
                    const rendered = (
                        <td key={index}> {renderValue(item, columnItem)}</td>
                    );
                    if (renderColumn) {
                        return renderColumn(item, columnItem, rendered);
                    }
                    return rendered;
                }
            })}
            {shouldShowActions && (
                <td>
                    <div className="actions">
                        {shouldView && (
                            <Link
                                className="view-campus"
                                to="/campus"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onView(item);
                                }}
                            >
                                View
                            </Link>
                        )}
                        {shouldEdit && (
                            <Link
                                className="action-edit"
                                to="#"
                                onClick={() => {
                                    onEdit(item);
                                }}
                            >
                                {editName}
                            </Link>
                        )}
                        {shouldDelete && (
                            <Link
                                className="action-delete"
                                to="#"
                                onClick={() => {
                                    onDelete(item);
                                }}
                            >
                                Delete
                            </Link>
                        )}
                        {shouldAssignFee && (
                            <Link
                                className="action-assign-fee"
                                to="#"
                                onClick={onAssignShowModal}
                            >
                                AssignFeePolicy
                            </Link>
                        )}
                        {shouldAssignDeliverable && (
                            <Link
                                className="action-assign-deliverable"
                                to="#"
                                onClick={() => {
                                    onAssignDeliverable(item);
                                }}
                            >
                                Assign Sub Deliverable
                            </Link>
                        )}

                        {/* ModalAssignFeePolicy*/}

                        <Modal
                            show={assignModal}
                            onHide={assignModalClose}
                            centered
                            className="assignfeeModal"
                        >
                            <AssignFeePolicy
                                onShow={assignModal}
                                onClose={assignModalClose}
                            />
                        </Modal>
                    </div>
                </td>
            )}
        </tr>
    );
};

export default forwardRef(Table);
