import React from 'react';
import GenericProfileForm from '../../../../Form/GenericProfileForm';

const AddProfessional = ({ onClose, id, scholarId }) => {
    const initialValues = {
        id: 1,
        SchoolName: 'School',
        FieldOfStudy: 'Field of Study',
        Degree: 'Degree',
        Grade: 'Grade',
        PassingOutYear: 0,
        Achievement: '',
        CreatedBy: null,
        IsDeleted: false,
        UserProfile: scholarId,
    };

    const fields = [
        {
            type: 'text',
            field: 'SchoolName',
            label: 'School/College Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'FieldOfStudy',
            label: 'Field Of Study',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Degree',
            label: 'Degree',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
        {
            type: 'text',
            field: 'Grade',
            label: 'Grade',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
        {
            type: 'text',
            field: 'PassingOutYear',
            label: 'Passing Out Year',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
        {
            type: 'textarea',
            field: 'Achievement',
            label: 'Achievement',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
    ];

    return (
        <div className="userprofile-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Add Qualification Details</h5>
                    <span>Update your qualification details here</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onClose}
                    src="/images/cross.svg"
                    alt={'Close Modal'}
                />
            </div>

            <GenericProfileForm
                fields={fields}
                id={id}
                initialValues={initialValues}
                onClose={onClose}
                endpoint={'Qualification'}
            />
        </div>
    );
};

export default AddProfessional;
