import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ExportButton } from '../Buttons/ExportButton';
import CustomCheckbox from '../Inputs/CustomCheckbox';
import DeleteModal from '../Modals/DeleteModal';
import Api from '../../../api/api';
import renderValue from '../../../helpers/renderValue';

const Table = ({
    shouldView = true,
    shouldDelete = true,
    shouldEdit = true,
    data,
    column,
    renderColumn,
    onView,
    onEdit,
    tableHeading,
    onDelete,
    refresh,
    resource,
    checkboxshouldView = true,
}) => {
    const [deleteModal, setdeleteModal] = useState(false);
    const [deleteMultiModal, setdeleteMultiModal] = useState(false);
    const deleteModalClose = () => setdeleteModal(false);
    const deleteModalShow = () => setdeleteModal(true);
    const deleteMultiModalClose = () => setdeleteMultiModal(false);
    const deleteMultiModalShow = () => setdeleteMultiModal(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showaction, setShowaction] = useState(false);

    const showDrop = () => {
        setShowaction(!showaction);
    };

    const toggleSelect = (item) => {
        let items = [...selectedItems];
        const position = items.indexOf(item);
        if (position > -1) {
            items.splice(position, 1);
        } else {
            items.push(item);
        }
        setSelectedItems(items);
    };

    const toggleSelectAll = () => {
        if (data.length !== selectedItems.length) {
            setSelectedItems([...data]);
        } else {
            setSelectedItems([]);
        }
    };

    const onDeleteLocal = (item) => {
        setSelectedItem(item);
        deleteModalShow();
    };

    function getItemId(item) {
        return item.Id || item.id;
    }

    const onDeleteSelected = () => {
        deleteMultiModalShow();
    };
    const onActuallyDeleteSelected = () => {
        let allPromises = selectedItems.map((item) => {
            return Api.delete(`/${resource}/DeleteEntity/${getItemId(item)}`);
        });

        Promise.allSettled(allPromises).then(() => {
            setSelectedItems([]);
            refresh();
            deleteMultiModalClose();
        });
    };

    const onActuallyDelete = () => {
        if (onDelete) {
            onDelete(selectedItem);
            return;
        }
        Api.delete(
            `/UserProfile/${resource}/DeleteEntity/${getItemId(selectedItem)}`,
        )
            .then((response) => {})
            .catch(console.log)
            .finally(() => {
                deleteModalClose();
                refresh();
            });
    };

    return (
        <div className="table-dash">
            <div className="table-content">
                <div className="delete-row ">
                    <span>{tableHeading}</span>
                    {selectedItems.length > 0 && (
                        <div className="delete-row-button">
                            <ExportButton
                                width="auto"
                                onClick={onDeleteSelected}
                                height="36px"
                                hover="#FEF9F9"
                                border="1px solid #F04438"
                                color="#F04438"
                                padding="0px 10px"
                                borderradius="6px"
                            >
                                Delete
                            </ExportButton>
                        </div>
                    )}
                </div>
                <table>
                    <thead>
                        <tr>
                            {checkboxshouldView && (
                                <th>
                                    <div className="table-checkbox">
                                        {checkboxshouldView && (
                                            <CustomCheckbox
                                                isChecked={
                                                    data.length ===
                                                    selectedItems.length
                                                }
                                                onChange={toggleSelectAll}
                                            />
                                        )}
                                        {column.map((item, index) => (
                                            <>
                                                {item.heading === 
                                                    'Name' && (
                                                    <div className="table-name-heading">
                                                        {item.heading}
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                </th>
                            )}
                            {column.map((item, index) => (
                                <>
                                    {item.heading !== 'Name' && (
                                        <TableHeadItems item={item} />
                                    )}
                                </>
                            ))}
                            <th className="action-th">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {data.map((item, index) => (
                            <TableRow
                                onEdit={onEdit}
                                toggleSelect={toggleSelect}
                                selectedItems={selectedItems}
                                item={item}
                                column={column}
                                shouldView={shouldView}
                                shouldDelete={shouldDelete}
                                shouldEdit={shouldEdit}
                                onDelete={onDeleteLocal}
                                renderColumn={renderColumn}
                                onView={onView}
                                checkboxshouldView={checkboxshouldView}
                                showDrop={showDrop}
                                showaction={showaction}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal
                show={deleteModal}
                onHide={deleteModalClose}
                centered
                className="deleteModal"
            >
                <DeleteModal
                    onClose={deleteModalClose}
                    onDelete={onActuallyDelete}
                />
            </Modal>
            <Modal
                show={deleteMultiModal}
                onHide={deleteMultiModalClose}
                centered
                className="deleteModal"
            >
                <DeleteModal
                    isMulti
                    onClose={deleteMultiModalClose}
                    onDelete={onActuallyDeleteSelected}
                />
            </Modal>
        </div>
    );
};

const TableHeadItems = ({ item }) => <th>{item.heading}</th>;
const TableRow = ({
    item,
    column,
    renderColumn,
    onView,
    onDelete,
    selectedItems,
    toggleSelect,
    checkboxshouldView,
    onEdit,
    shouldView,
    shouldDelete,
    shouldEdit,
}) => {
    return (
        <tr>
            {checkboxshouldView && (
                <td>
                    <div className="table-checkbox">
                        {checkboxshouldView && (
                            <CustomCheckbox
                                isChecked={selectedItems.indexOf(item) > -1}
                                onChange={() => {
                                    toggleSelect(item);
                                }}
                            />
                        )}
                        {column.map((columnItem, index) => {
                            if (columnItem.heading === 'Name') {
                                const rendered = (
                                    <div key={index}>
                                        {renderValue(item, columnItem)}
                                    </div>
                                );
                                if (renderColumn) {
                                    return renderColumn(
                                        item,
                                        columnItem,
                                        rendered,
                                    );
                                }
                                return rendered;
                            }
                        })}
                    </div>
                </td>
            )}
            {column.map((columnItem, index) => {
                if (columnItem.heading !== 'Name') {
                    const rendered = (
                        <td key={index}> {renderValue(item, columnItem)}</td>
                    );
                    if (renderColumn) {
                        return renderColumn(item, columnItem, rendered);
                    }
                    return rendered;
                }
            })}
            <td>
                <div className="actions">
                    {shouldView && (
                        <Link
                            className="view-campus"
                            to="/campus"
                            onClick={() => {
                                onView(item);
                            }}
                        >
                            View
                        </Link>
                    )}
                    {shouldDelete && (
                        <Link
                            className="action-delete"
                            to="#"
                            onClick={() => {
                                onDelete(item);
                            }}
                        >
                            Delete
                        </Link>
                    )}
                    {shouldEdit && (
                        <Link
                            className="action-edit"
                            to="#"
                            onClick={() => {
                                onEdit(item);
                            }}
                        >
                            Edit
                        </Link>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default Table;
