import React from 'react';
import Header from '../../../../../../../components/Header/Header';
import Heading from '../../../../../../../components/Heading/Heading';
import Progressbar from '../../../../../../../components/Progress Bar/Progressbar';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import PiorScholarInformationContent from './PiorScholarInformationContent';
import MediaQuery from 'react-responsive';

const PiorScholarInformation = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    title="Scholars"
                    breadcrumbs="My Profile"
                    href="/scholar"
                />
                <Heading title="My Profile" description="" />
                <div className="user-profile-section">
                    <Progressbar />
                    <PiorScholarInformationContent />
                </div>
            </div>
        </div>
    );
};

export default PiorScholarInformation;
