import React from 'react';
import Sidebar from '../../../../../../../../../components/Sidebar/Sidebar';
import AddProgramContent from './AddProgramContent';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import FormHeader from '../../../../../../../../../components/Header/FormHeader';
import FormHeading from '../../../../../../../../../components/Heading/FormHeading';
import { LinkButton } from '../../../../../../../../../components/Buttons/LinkButton';
import Backarrow from '../../../../../../../../../../images/back-arrow.svg';

const AddProgram = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <div className="heading-button-mobile">
                    <FormHeader
                        homelink="/dashboard"
                        title="Campuses"
                        breadcrumb="Programs"
                        breadcrumbs="Add Program"
                        href="/dashboard"
                        innerpath="/program"
                    />
                    <FormHeading
                        title="Add Program"
                        description="Enter the following details to add program"
                    />
                    <MediaQuery maxWidth={1023}>
                    <div className='back-button'>
                        <LinkButton
                            linktext="Back to Programs"
                            icon={Backarrow}
                            href="/program"
                            width="204px"
                            height="44px"
                            backgroundcolor="none"
                            color="#5D43A5"
                            fontsize="16px"
                            hover="none"
                        />
                    </div>
                    </MediaQuery>
                    <AddProgramContent />
                </div>
            </div>
        </div>
    );
};

export default AddProgram;
