import React, { useState } from 'react';
import { Button } from '../../../../Buttons/Button';
import { ExportButton } from '../../../../Buttons/ExportButton';
import { SimpleInput } from '../../../../Inputs/SimpleInput';

const AddBook = (props) => {
    const initialValues = {
        publisher: '',
        titleofbook: '',
        authorname: '',
        isbnno: '',
        level: '',
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (event) => {
        let i = { ...formValues };
        i[event.target.name] = event.target.value;
        setFormValues(i);
    };

    const handleSave = (e) => {
        e.preventDefault();
        let errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            handleSave({ ...formValues });
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.publisher) {
            errors.publisher = 'Publisher name is required*';
        }

        if (!values.titleofbook) {
            errors.titleofbook = 'Title of Book is required*';
        }

        if (!values.authorname) {
            errors.authorname = 'Author(s) Name is  required*';
        }

        if (!values.isbnno) {
            errors.isbnno = 'ISBN No is required*';
        }

        if (!values.level) {
            errors.level = 'Level is required*';
        }
        return errors;
    };
    return (
        <div className="userprofile-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Add Book</h5>
                    <span>Update your book details here</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={props.onClose}
                    src="/images/cross.svg"
                />
            </div>

            <div className="invite-supervisor-content">
                <div className="invite-supervisor">
                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter publisher"
                                label="Publisher*"
                                value={formValues.publisher}
                                onChange={handleChange}
                                error={formErrors.publisher}
                            />
                        </div>
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter title of book"
                                label="Title of Book*"
                                value={formValues.titleofbook}
                                onChange={handleChange}
                                error={formErrors.titleofbook}
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter author(s) name"
                                label="Author(s) Name*"
                                value={formValues.authorname}
                                onChange={handleChange}
                                error={formErrors.authorname}
                            />
                        </div>
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter ISBN No."
                                label="ISBN No.*"
                                value={formValues.isbnno}
                                onChange={handleChange}
                                error={formErrors.isbnno}
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="patent-issue-input">
                            <SimpleInput
                                placeholder="Enter level"
                                label="Level*"
                                value={formValues.level}
                                onChange={handleChange}
                                error={formErrors.level}
                            />
                        </div>
                    </div>
                </div>

                <div className="cb-buttons-userprofile">
                    <ExportButton hover="#F9FAFB" border="1px solid #D0D5DD">
                        Cancel
                    </ExportButton>
                    <Button
                        width="auto"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddBook;
