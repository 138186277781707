import React, { useState } from 'react';
import { Button } from '../../../../../../../components/Buttons/Button';
import { ExportButton } from '../../../../../../../components/Buttons/ExportButton';
import Heading from '../../../../../../../components/Heading/Heading';
import { SimpleInput } from '../../../../../../../components/Inputs/SimpleInput';

const BankDetailContent = () => {
    const initialValues = {
        accountholdername: '',
        accountnumber: '',
        ifsccode: '',
        branchname: '',
        pancard: '',
        aadharcard: '',
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (event) => {
        let i = { ...formValues };
        i[event.target.name] = event.target.value;
        setFormValues(i);
    };

    const handleSave = (e) => {
        e.preventDefault();
        let errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            handleSave({ ...formValues });
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.accountholdername) {
            errors.accountholdername = 'Account Holder Name is required*';
        }

        if (!values.accountnumber) {
            errors.accountnumber = 'Account Number is required*';
        }

        if (!values.designation) {
            errors.designation = 'Designation is required*';
        }

        if (!values.ifsccode) {
            errors.ifsccode = 'IFSC Code is required*';
        }

        if (!values.bankname) {
            errors.bankname = 'Bank Name is required*';
        }

        if (!values.branchname) {
            errors.branchname = 'Branch Name is required*';
        }

        if (!values.aadharcard) {
            errors.aadharcard = 'Aadhar Card Number is required*';
        }

        if (!values.pancard) {
            errors.pancard = 'Pan Card Number is required*';
        }
        return errors;
    };

    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title="Bank Details"
                    description="Update your bank details here."
                />
            </div>

            <div className="personal-info">
                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your account holder name"
                            label="Account Holder Name"
                            value={formValues.accountholdername}
                            onChange={handleChange}
                            error={formErrors.accountholdername}
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your account number"
                            label="Account Number"
                            value={formValues.accountnumber}
                            onChange={handleChange}
                            error={formErrors.accountnumber}
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your IFSC code"
                            label="IFSC Code"
                            value={formValues.ifsccode}
                            onChange={handleChange}
                            error={formErrors.ifsccode}
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your swift code"
                            label="Swift Code"
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your bank name"
                            label="Bank Name"
                            value={formValues.bankname}
                            onChange={handleChange}
                            error={formErrors.bankname}
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter branch name"
                            label="Branch"
                            value={formValues.branchname}
                            onChange={handleChange}
                            error={formErrors.branchname}
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your aadhar card number"
                            label="Aadhar Card Number"
                            value={formValues.aadharcard}
                            onChange={handleChange}
                            error={formErrors.aadharcard}
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your pan card number"
                            label="PAN Card Number"
                            value={formValues.pancard}
                            onChange={handleChange}
                            error={formErrors.pancard}
                        />
                    </div>
                </div>

                <div className="bottom-line-personal-info"></div>

                <div className="cb-buttons-userprofile">
                    <ExportButton hover="#F9FAFB" border="none">
                        Skip
                    </ExportButton>
                    <Button
                        width="auto"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                        onClick={handleSave}
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default BankDetailContent;
