import React from 'react';
import GenericForm from '../../../../../components/Form/GenericForm';
import qs from 'qs';

const AddAcademicYearContent = () => {
    let filters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const initialValues = {
        Name: '',
        StartDate: '',
        Code: '',
        EndDate: '',
        CampusId: filters.CampusId || 0,
        IsActive: true,
    };

    const fields = [
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'Academic year will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
        },
        {
            type: 'text',
            field: 'Name',
            label: 'Academic year name',
            description: 'Name of the academic year',
            estric: '*',
        },
        {
            type: 'date',
            field: 'StartDate',
            label: 'Start Date',
            description: 'Start date of the academic year',
            estric: '*',
        },
        {
            type: 'date',
            field: 'EndDate',
            label: 'End Date',
            description: 'End date of the academic year',
            estric: '*',
        },
    ];

    return (
        <GenericForm
            fields={fields}
            initialValues={initialValues}
            endpoint={'AcademicStructure/AcademicYear'}
            redirectTo={'/academic-year'}
        />
    );
};

export default AddAcademicYearContent;
