import React from 'react';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Sidebar from '../../../components/Sidebar/Sidebar';
import GeneralView from '../View Campus/Subcomponents/GeneralView';
import { useParams } from 'react-router-dom';
import useDetailApi from '../../../../hooks/useDetailApi';
import Backarrow from '../../../../images/back-arrow.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';

const ViewCluster = () => {
    const { id } = useParams();
    const { loading, item } = useDetailApi('Infrastructure/Cluster', id);

    const fields = [
        {
            type: 'select',
            field: 'CampusName',
            label: 'Campus Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Name',
            label: 'Registry name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Registry Code',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
    ];
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <Sidebar />
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    breadcrumb="Registries"
                    breadcrumbs="View Registry"
                    innerpath="/registry"
                />
                <Heading
                    title="Registry Details"
                    description="View all details of the registry below"
                    linktext="Back to Registries"
                    icon={Backarrow}
                    href="/registry"
                    width="auto"
                    height="44px"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    padding="0px 20px !important"
                    className="view-back-button"
                />
                {loading ? (
                    <div> Loading... </div>
                ) : (
                    <>
                        <GeneralView campus={item} fields={fields} />
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewCluster;
