import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Heading from '../../../../../../../components/Heading/Heading';
import Plus from '../../../../../../../../images/plus.svg';
import { Modal } from 'react-bootstrap';
import AddConference from '../../../../../../../components/Modals/User Profile Modals/Subcomponents/Add Conference Details/AddConference';
import useProfileListApi from '../../../../../../../../hooks/useProfileListApi';
import ProfileTable from '../../../../../../../components/Table/ProfileTable';

const ConferenceDetailContent = ({ id }) => {
    const { rows, refresh } = useProfileListApi('Conference', id);

    const navigate = useNavigate();

    const [objectId, setObjectId] = useState(null);
    const [userprofileModal, setuserprofileModal] = useState(false);
    const userprofileModalClose = () => {
        setuserprofileModal(false);
        refresh();
    };
    const userprofileModalShow = () => setuserprofileModal(true);

    const column = [
        {
            heading: 'Title',
            value: 'Title',
        },
        {
            heading: 'Organizer',
            value: 'Organizer',
        },
        {
            heading: 'Link',
            value: 'Link',
        },
        {
            heading: 'ISSN',
            value: 'ISSN',
        },
        {
            heading: 'Year',
            value: 'Year',
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'cluster') {
            return (
                <td>
                    <a href={`/registry?CampusId=${row.CampusId}`}>Registry</a>
                </td>
            );
        }
        return rendered;
    };

    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title="Conference"
                    description="Upload your conference details here."
                    linktext="Add More"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    onClick={userprofileModalShow}
                />
            </div>

            <ProfileTable
                shouldView={false}
                checkboxshouldView={false}
                resource={'Conference'}
                refresh={refresh}
                data={rows}
                column={column}
                renderColumn={renderColumn}
                tableHeading="All Conference"
                onEdit={(item) => {
                    setObjectId(item.id);
                    setuserprofileModal(true);
                }}
            />

            {/* Modal */}

            <Modal
                show={userprofileModal}
                onHide={userprofileModalClose}
                centered
                className="userprofileModal"
            >
                <AddConference
                    scholarId={id}
                    id={objectId}
                    onShow={userprofileModal}
                    onClose={userprofileModalClose}
                />
            </Modal>
        </div>
    );
};

export default ConferenceDetailContent;
