import React from 'react';
import styled from 'styled-components';
import Userprofile from '../../../images/profile.svg';
import UploadDragDrop from '../Upload File/UploadDragDrop';
import Api from '../../../api/api';
import RemovedImageIcon from '../../../images/remove-image.svg';
import NoProfile from '../../../images/no-profile-image.svg';

const InputContainer = styled.div`
    width: 100%;
    float: left;

    > label {
        font-size: 14px;
        font-weight: 400;
        color: #344054;
        padding-bottom: 4px;
    }

    > span {
        font-size: 14px;
        color: #f04438;
    }
`;

const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(props) => (props.hasError ? '#F04438' : '#D0D5DD')};
  border-radius: 8px;
  padding: 10px 10px;

  &:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #f4ebff;
  }

  > img {
    width: 18px;
    height: 18px;
    margin: 8px;
    color: #c1c1c1;
  }
`;

const InputBase = styled.textarea`
  height: auto;
  width: 100%;
  color: #1d2939;
  font-size: 15px;
  font-weight: 400;
  border: none;
  background: transparent;
  outline: none;

  &::placeholder {
    color: #c1c1c1;
    font-weight: 300;
  }
`;

export const FileInput = ({
                              onClick,
                              onChange,
                              type,
                              value,
                              placeholder,
                              name,
                              error,
                              righticon,
                              lefticon,
                              label,
                              successMsg,
                              tooltipicon,
                              tooltiptext,
                              remove,
                              fileType
                          }) => {
    return (
        <InputContainer>
            <div className="click-to-upload ">
                <div className="personal-info-upload">
                    {value ? (
                        <div className="uploaded-images-col">
                            <img
                                className="uploaded-images-size"
                                src={`${Api.api_url}/uploads/${value}`}
                                alt={'Userprofile'}
                            />
                            <div
                                className="remove-uploaded-image"
                                onClick={() => {
                                    onChange({
                                        target: {name: name, value: ''},
                                    });
                                }}
                            >
                                <img
                                    src={RemovedImageIcon}
                                    alt={RemovedImageIcon}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="no-profile-image">
                            <img
                                className="dummy-no-profile-image"
                                src={NoProfile} alt={NoProfile}/>
                        </div>
                    )}
                    <div className="personal-info-uploadfile">
                        <UploadDragDrop
                            fileType={fileType}
                            onChange={(v) => {
                                onChange({target: {name: name, value: v}});
                            }}
                        />
                    </div>
                </div>
            </div>
        </InputContainer>
    );
};
