import React, { useState } from 'react';
import AddressContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Address/AddressContent';
import AwardDetailContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Award Details/AwardDetailContent';
import BankDetailContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Bank Details/BankDetailContent';
import BookChapterDetailContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Book Chapter Details/BookChapterDetailContent';
import BookDetailContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Book Details/BookDetailContent';
import ConferenceDetailContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Conference Details/ConferenceDetailContent';
import JournalDetailContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Journal Details/JournalDetailContent';
import PersonalInfoContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Personal Info/PersonalInfoContent';
import PiorScholarInformationContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Pior Scholar Information/PiorScholarInformationContent';
import ProfessionalDetailContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Professional Details/ProfessionalDetailContent';
import ProjectContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Projects/ProjectContent';
import PublicationDetailContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Publications Details/PublicationDetailContent';
import QualificationContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Qualification/QualificationContent';
import SocialSiteContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Social Sites/SocialSiteContent';
import UploadDocumentsContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Upload Documents/UploadDocumentsContent';
import SelectInput from '../Inputs/SelectInput';
import PatentDetailContent from '../../main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Generic/GenericContent';

const options = [
    { value: 'personal information', label: 'Personal Information' },
    { value: 'address', label: 'Address' },
    { value: 'bank details', label: 'Bank Details' },
    { value: 'upload documents', label: 'Upload Documents' },
    { value: 'professional details', label: 'Professional Details' },
    { value: 'qualification', label: 'Qualification' },
    { value: 'projects', label: 'Projects' },
    { value: 'patent details', label: 'Patent Details' },
    { value: 'conference details', label: 'Conference Details' },
    { value: 'award details', label: 'Award Details' },
    { value: 'book chapter details', label: 'Book Chapter Details' },
    { value: 'book details', label: 'Book Details' },
    { value: 'prior scholar information', label: 'Prior Scholar Information' },
    { value: 'social sites', label: 'Social Sites' },
    { value: 'journal details', label: 'Journal Details' },
    { value: 'publication details', label: 'Publication Details' },
];

const UserProfileSelectDropdown = () => {
    const [option, setOption] = useState('');
    const handleUserProfileInfo = (event) => {
        setOption(event.value);
    };

    return (
        <div className="invite-supervisor-content">
            <div className="invite-supervisor">
                <div className="invite-supervisor-input">
                    <div className="aasign-supervisor-select">
                        <div className="personal-info-about">
                            Select User Profile Info
                        </div>
                        <SelectInput
                            placeholder="Select user profile info"
                            options={options}
                            onChange={handleUserProfileInfo}
                        />
                    </div>
                    {option === 'personal information' ? (
                        <PersonalInfoContent />
                    ) : (
                        ''
                    )}
                    {option === 'address' ? <AddressContent /> : ''}
                    {option === 'bank details' ? <BankDetailContent /> : ''}
                    {option === 'upload documents' ? (
                        <UploadDocumentsContent />
                    ) : (
                        ''
                    )}
                    {option === 'professional details' ? (
                        <ProfessionalDetailContent />
                    ) : (
                        ''
                    )}
                    {option === 'qualification' ? <QualificationContent /> : ''}
                    {option === 'projects' ? <ProjectContent /> : ''}
                    {option === 'patent details' ? <PatentDetailContent /> : ''}
                    {option === 'conference details' ? (
                        <ConferenceDetailContent />
                    ) : (
                        ''
                    )}
                    {option === 'award details' ? <AwardDetailContent /> : ''}
                    {option === 'book chapter details' ? (
                        <BookChapterDetailContent />
                    ) : (
                        ''
                    )}
                    {option === 'book details' ? <BookDetailContent /> : ''}
                    {option === 'prior scholar information' ? (
                        <PiorScholarInformationContent />
                    ) : (
                        ''
                    )}
                    {option === 'social sites' ? <SocialSiteContent /> : ''}
                    {option === 'journal details' ? (
                        <JournalDetailContent />
                    ) : (
                        ''
                    )}
                    {option === 'publication details' ? (
                        <PublicationDetailContent />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserProfileSelectDropdown;
