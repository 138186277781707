import React, { useState } from 'react';
import useListApi from '../../../../../../../../hooks/useListApi';
import { useNavigate } from 'react-router-dom';
import Heading from '../../../../../../../components/Heading/Heading';
import Table from '../../../../../../../components/Table/Table';
import Plus from '../../../../../../../../images/plus.svg';
import { Modal } from 'react-bootstrap';
import AddBook from '../../../../../../../components/Modals/User Profile Modals/Subcomponents/Add Book Details/AddBook';

const BookDetailContent = () => {
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Campus');

    const navigate = useNavigate();

    const [userprofileModal, setuserprofileModal] = useState(false);
    const userprofileModalClose = () => setuserprofileModal(false);
    const userprofileModalShow = () => setuserprofileModal(true);

    const column = [
        {
            heading: 'Title',
            value: '',
        },
        {
            heading: 'Publisher',
            value: '',
        },
        {
            heading: 'Level',
            value: '',
        },
        {
            heading: 'ISBN Number',
            value: '',
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'cluster') {
            return (
                <td>
                    <a href={`/registry?CampusId=${row.CampusId}`}>Registry</a>
                </td>
            );
        }
        return rendered;
    };

    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title="Books"
                    description="Upload your books details here."
                    linktext="Add More"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    onClick={userprofileModalShow}
                />
            </div>

            <Table
                shouldView={false}
                checkboxshouldView={false}
                resource={'Campus'}
                refresh={refresh}
                data={rows}
                totalRecords={totalRecords}
                perPage={recordsPerPage}
                setPerPage={setRecordsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                column={column}
                renderColumn={renderColumn}
                tableHeading="All Books"
                onView={(item) => {
                    window.location.href = `/campus/${item.CampusId}`;
                }}
            />

            {/* Modal */}

            <Modal
                show={userprofileModal}
                onHide={userprofileModalClose}
                centered
                className="userprofileModal"
            >
                <AddBook
                    onShow={userprofileModal}
                    onClose={userprofileModalClose}
                />
            </Modal>
        </div>
    );
};

export default BookDetailContent;
