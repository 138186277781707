import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../Buttons/Button';
import { ExportButton } from '../../../../../Buttons/ExportButton';
import { FixedInput } from '../../../../../Inputs/FixedInput';
import { FixedInputPlaceholder } from '../../../../../Inputs/FixedInputPlaceholder';
import SelectInput from '../../../../../Inputs/SelectInput';
import MediaQuery from 'react-responsive';
import FeeDiscountSelectDropdown from './Subcomponent/Mobile Design/Fee Discount/FeeDiscountSelectDropdown';
import Api from '../../../../../../../api/api';
import formatNumber from '../../../../../../../helpers/formatNumber';
import { SimpleInput } from '../../../../../Inputs/SimpleInput';
import FeeWaiver from './Subcomponent/Mobile Design/Fee Waiver/FeeWaiver';

const amount = [
    { value: 'amount', label: 'Amount' },
    // { value: '% percent', label: '% Percent' },
];

const FeeDiscount = ({ onClose, id, paid }) => {
    const [condition] = useState(false);
    const [scholarFees, setScholarFees] = useState([]);
    const [discounts, setDiscounts] = useState([]);

    useEffect(() => {
        Api.get(`/FeeStructure/ScholarFee/GetEntities/${id}`)
            .then((response) => {
                let left = paid;
                let sf = response.map((ScholarFee) => {
                    let paidAmount =
                        left > ScholarFee.PayableAmount
                            ? ScholarFee.PayableAmount
                            : left > 0
                            ? left
                            : 0;
                    let out = {
                        ...ScholarFee,
                        Paid: paidAmount === ScholarFee.PayableAmount,
                    };
                    left -= ScholarFee.PayableAmount;
                    return out;
                });
                setScholarFees(sf);
            })
            .catch();
    }, [paid, id]);

    useEffect(() => {
        setDiscounts(
            scholarFees.map((scholarFee) => {
                let discount =
                    scholarFee.PolicyFeeAmount - scholarFee.PayableAmount;
                return {
                    ScholarFeeId: scholarFee.id,
                    MilestoneTitle: scholarFee.Milestone.Code,
                    PolicyFeeAmount: scholarFee.PolicyFeeAmount,
                    PayableAmount: scholarFee.PayableAmount,
                    Discount: discount,
                    Paid: scholarFee.Paid,
                };
            }),
        );
    }, [scholarFees]);

    const submit = () => {
        Api.post(
            '/FeeStructure/ScholarFee/ApplyDiscount',
            discounts.map((d) => {
                return {
                    ScholarFeeId: d.ScholarFeeId,
                    Discount: null,
                    PayableAmount: d.PayableAmount,
                };
            }),
        )
            .then(() => {
                onClose();
            })
            .catch(console.log);
    };

    return (
        <div className="fee-policy-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <span>Fee Waiver</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onClose}
                    src="/images/cross.svg"
                />
            </div>
            <div>
                <MediaQuery maxWidth={1023}>
                    {!condition && <FeeWaiver/>}
                </MediaQuery>
            </div>
            <div className="fee-discount-inputs">
                <div className="fixed-milestone-input">
                    <div className="fee-discount-headings">Deliverables</div>
                </div>
                <div className="fixed-amount-input">
                    <div className="fee-discount-headings">Amounts</div>
                </div>
                <div className="discount-type-input">
                    <div className="fee-discount-headings">Fee Waiver Type</div>
                </div>
                <div className="discount-input">
                    <div className="fee-discount-headings">Fee Waiver</div>
                </div>
                <div className="after-discount-input">
                    <div className="fee-discount-headings">
                        After Fee Waiver
                    </div>
                </div>
            </div>
            {discounts.map((discount, index) => {
                return (
                    <div className="fee-discount-inputs">
                        <div className="fixed-milestone-input">
                            <FixedInput fixedtext={discount.MilestoneTitle} />
                        </div>
                        <div className="fixed-amount-input">
                            <FixedInputPlaceholder
                                fixedinputtext={formatNumber(
                                    discount.PolicyFeeAmount,
                                )}
                                fixedtext="₹"
                                width="40px"
                            />
                        </div>
                        <div className="discount-type-input">
                            <SelectInput
                                options={amount}
                                placeholder="Select fee waiver type"
                                value={'amount'}
                            />
                        </div>
                        <div className="discount-type-input">
                            <SimpleInput
                                onChange={(e) => {
                                    let d = [...discounts];
                                    d[index].Discount = e.target.value;
                                    d[index].PayableAmount =
                                        d[index].PolicyFeeAmount -
                                        d[index].Discount;
                                    setDiscounts(d);
                                }}
                                placeholder="Enter fee waiver"
                                value={discount.Discount}
                            />
                        </div>
                        <div className="after-discount-input">
                            <FixedInputPlaceholder
                                fixedinputtext={formatNumber(
                                    discount.PayableAmount,
                                )}
                                fixedtext="₹"
                                width="40px"
                            />
                        </div>
                    </div>
                );
            })}

            <div className="discount-footer-content">
                <div className="discount-footer">
                    <div className="total-heading">
                        <h4>Total Amount</h4>
                    </div>
                    <div className="total-amount-heading">
                        <h4>
                            ₹
                            {formatNumber(
                                discounts.reduce((p, t) => {
                                    return p + t.PolicyFeeAmount;
                                }, 0),
                            )}
                        </h4>
                    </div>
                </div>
                <div className="after-discount-footer">
                    <div className="total-amount-heading">
                        <h4>
                            ₹
                            {formatNumber(
                                discounts.reduce((p, t) => {
                                    return p + parseFloat(t.Discount);
                                }, 0),
                            )}
                        </h4>
                    </div>
                    <div className="total-amount-heading">
                        <h4>
                            ₹
                            {formatNumber(
                                discounts.reduce((p, t) => {
                                    return p + t.PayableAmount;
                                }, 0),
                            )}
                        </h4>
                    </div>
                </div>
            </div>

            <div className="bottom-line-discount"></div>
            <div className="cb-buttons-discount">
                <ExportButton
                    width="460px"
                    height="44px"
                    onClick={onClose}
                    hover="#f9fafb"
                    border="1px solid #d0d5dd"
                >
                    Cancel
                </ExportButton>
                <Button
                    onClick={submit}
                    width="460px"
                    height="44px"
                    hover="#5D43A5"
                    padding="0px 20px"
                >
                    Save
                </Button>
            </div>
        </div>
    );
};

export default FeeDiscount;
