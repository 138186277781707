import React, { useState } from 'react';
import { LinkButton } from '../../../../../../../components/Buttons/LinkButton';
import Discount from '../../../../../../../../images/discount.svg';
import { Modal } from 'react-bootstrap';
import Collection from '../../../../../../../components/Modals/Fee Modals/Assign Fee Policy/Subcomponents/Collection/Collection';
import FeeDiscount from '../../../../../../../components/Modals/Fee Modals/Assign Fee Policy/Subcomponents/Discount/FeeDiscount';
import formatNumber from '../../../../../../../../helpers/formatNumber';
import CollectionIcon from '../../../../../../../../images/collection.svg';

const AssigendFeePolicyAmount = ({ summary, id, refresh, scholar }) => {
    const [collectionModal, setcollectionModal] = useState(false);
    const collectionModalClose = () => {
        refresh();
        setcollectionModal(false);
    };
    const collectionModalShow = () => setcollectionModal(true);

    const [discountModal, setdiscountModal] = useState(false);
    const discountModalClose = () => {
        refresh();
        setdiscountModal(false);
    };
    const discountModalShow = () => {
        setdiscountModal(true);
    };

    return (
        <div className="assigned-fee-policy-amount">
            <div className="assigned-amount">
                <div className="amount-status">
                    <div className="total-amount">
                        <h4>Total Amount</h4>
                        <span>₹{formatNumber(summary.Total)}</span>
                    </div>
                    <div className="total-paid">
                        <h4>Total Paid</h4>
                        <span>₹{formatNumber(summary.AmountPaid)}</span>
                    </div>
                    <div className="remaining-amount">
                        <h4>Remaining Amount</h4>
                        <span>₹{formatNumber(summary.PendingAmount)}</span>
                    </div>
                </div>
                <div className="fee-policy-buttons">
                    <div className="collection-button">
                        <LinkButton
                            width="auto"
                            height="40px"
                            padding="0px 20px !important"
                            border="1px solid #7F56D9"
                            backgroundcolor="none"
                            hover="#F9F5FF"
                            color="#7F56D9"
                            icon={CollectionIcon}
                            linktext="Collection"
                            onClick={collectionModalShow}
                        ></LinkButton>
                    </div>
                    <div className="discount-button">
                        <LinkButton
                            width="auto"
                            height="40px"
                            padding="0px 20px !important"
                            hover="#5D43A5"
                            icon={Discount}
                            linktext="Fee Waiver"
                            onClick={discountModalShow}
                        ></LinkButton>
                    </div>
                </div>
            </div>

            {/* ModalCollection */}

            <Modal
                show={collectionModal}
                onHide={collectionModalClose}
                centered
                className="collectionModal"
            >
                <Collection
                    id={scholar.User_id}
                    payable={summary.PendingAmount}
                    onShow={collectionModal}
                    onClose={collectionModalClose}
                />
            </Modal>

            {/* ModalDiscount */}

            <Modal
                show={discountModal}
                onHide={discountModalClose}
                centered
                className="discountModal"
            >
                <FeeDiscount
                    paid={summary.AmountPaid}
                    id={id}
                    onShow={discountModal}
                    onClose={discountModalClose}
                />
            </Modal>
        </div>
    );
};

export default AssigendFeePolicyAmount;
