import React, { useState } from 'react';
import Heading from '../../../../../../../components/Heading/Heading';
import Plus from '../../../../../../../../images/plus.svg';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import AddQualification from '../../../../../../../components/Modals/User Profile Modals/Subcomponents/Add Qualification Details/AddQualification';
import useProfileListApi from '../../../../../../../../hooks/useProfileListApi';
import ProfileTable from '../../../../../../../components/Table/ProfileTable';

const QualificationContent = ({ id }) => {
    const { rows, refresh } = useProfileListApi('Qualification', id);

    const navigate = useNavigate();

    const [objectId, setObjectId] = useState(null);
    const [userprofileModal, setuserprofileModal] = useState(false);
    const userprofileModalClose = () => {
        refresh();
        setuserprofileModal(false);
    };
    const userprofileModalShow = () => setuserprofileModal(true);

    const column = [
        {
            heading: 'School/College',
            value: 'SchoolName',
        },
        {
            heading: 'Degree',
            value: 'Degree',
        },
        {
            heading: 'Field of Study',
            value: 'FieldOfStudy',
        },
        {
            heading: 'Passing Out',
            value: 'PassingOutYear',
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'cluster') {
            return (
                <td>
                    <a href={`/registry?CampusId=${row.CampusId}`}>Registry</a>
                </td>
            );
        }
        return rendered;
    };

    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title="Qualifications"
                    description="Upload your qualifications here."
                    linktext="Add More"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    onClick={userprofileModalShow}
                />
            </div>

            <ProfileTable
                shouldView={false}
                checkboxshouldView={false}
                resource={'Qualification'}
                refresh={refresh}
                data={rows}
                column={column}
                shouldAssignFee={false}
                renderColumn={renderColumn}
                tableHeading="All Qualifications"
                onEdit={(item) => {
                    setObjectId(item.id);
                    setuserprofileModal(true);
                }}
            />

            {/* Modal */}

            <Modal
                show={userprofileModal}
                onHide={userprofileModalClose}
                centered
                className="userprofileModal"
            >
                <AddQualification
                    onShow={userprofileModal}
                    onClose={userprofileModalClose}
                    scholarId={id}
                    id={objectId}
                />
            </Modal>
        </div>
    );
};

export default QualificationContent;
