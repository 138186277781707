import React, { useRef } from 'react';
import GenericForm from '../../../../../../../../../../components/Form/GenericForm';
import qs from 'qs';

const AddDisciplineContent = () => {
    let filters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const formRef = useRef(null);

    const initialValues = {
        Name: '',
        Code: '',
        Description: '',
        ClusterId: filters.ClusterId || 0,
        CampusId: filters.CampusId || 0,
        ResearchCenterId: filters.ResearchCenterId || 0,
        ProgramId: filters.ProgramId || 0,
        CreatedBy: null,
    };

    const fields = [
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'Specialization will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ClusterId: '',
                    ResearchCenterId: '',
                    ProgramId: '',
                });
            },
        },
        {
            type: 'select',
            field: 'ClusterId',
            label: 'Select Registry',
            description:
                'Specialization will be added to the selected Registry',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ResearchCenterId: '',
                    ProgramId: '',
                });
            },
        },
        {
            type: 'select',
            field: 'ResearchCenterId',
            label: 'Select Discipline',
            description: 'Specialization will be added to the Discipline',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({ ProgramId: '' });
            },
        },
        {
            type: 'select',
            field: 'ProgramId',
            label: 'Select Program',
            estric: '*',
            description:
                'Specialization will be added to the selected Discipline',
            options: {
                type: 'dynamic',
                endpoint: 'AcademicStructure/Program',
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return (
                        cluster.ResearchCenterId === formValue.ResearchCenterId
                    );
                },
            },
        },
        {
            type: 'text',
            field: 'Name',
            label: 'Specialization name',
            description: 'Name of the Specialization',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Specialization Code',
            description: 'Unique identifier of the Specialization',
            estric: '*',
        },
        {
            type: 'textarea',
            field: 'Description',
            label: 'Specialization Description',
            description: 'Detailed description of the Specialization',
            estric: '*',
        },
    ];

    return (
        <GenericForm
            ref={formRef}
            fields={fields}
            initialValues={initialValues}
            endpoint={'AcademicStructure/Discipline'}
            redirectTo={'/specialization'}
        />
    );
};

export default AddDisciplineContent;
