import React, { useState } from 'react';
import { Button } from '../../../../../../../components/Buttons/Button';
import { ExportButton } from '../../../../../../../components/Buttons/ExportButton';
import Heading from '../../../../../../../components/Heading/Heading';
import { SimpleInput } from '../../../../../../../components/Inputs/SimpleInput';
import Userprofile from '../../../../../../../../images/profile.svg';
import UploadDragDrop from '../../../../../../../components/Upload File/UploadDragDrop';
import Calendar from '../../../../../../../../images/calendar.svg';
import Email from '../../../../../../../../images/email.svg';
import Call from '../../../../../../../../images/call.svg';
import SelectInput from '../../../../../../../components/Inputs/SelectInput';

const gender = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Others' },
];

const bloodgroup = [{ value: 'a', label: 'A+' }];

const nationality = [{ value: 'indian', label: 'Indian' }];

const maritalstatus = [
    { value: 'single', label: 'Single' },
    { value: 'married', label: 'Married' },
];

const physicallydisabled = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
];

const quota = [{ value: 'defence', label: 'Defence' }];

const category = [{ value: 'sc', label: 'SC' }];

const PersonalInfoContent = () => {
    const initialValues = {
        firstname: '',
        lastname: '',
        fathersfirstname: '',
        fatherslastname: '',
        gender: '',
        bloodgroup: '',
        dob: '',
        quota: '',
        category: '',
        nationality: '',
        email: '',
        contactnumber: '',
        emergencycontactnumber: '',
        domicle: '',
        physicallydisabled: '',
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (event) => {
        let i = { ...formValues };
        i[event.target.name] = event.target.value;
        setFormValues(i);
    };

    const handleSave = (e) => {
        e.preventDefault();
        let errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            handleSave({ ...formValues });
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.firstname) {
            errors.firstname = 'First Name is required*';
        }

        if (!values.lastname) {
            errors.lastname = 'Last Name is required*';
        }

        if (!values.fathersfirstname) {
            errors.fathersfirstname = 'Fathers First Name is required*';
        }

        if (!values.fatherslastname) {
            errors.fatherslastname = 'Fathers Last Name is required*';
        }

        if (!values.gender) {
            errors.gender = 'Gender is required*';
        }

        if (!values.bloodgroup) {
            errors.bloodgroup = 'Blood Group is required*';
        }

        if (!values.dob) {
            errors.dob = 'Date of Birth is required*';
        }

        if (!values.quota) {
            errors.quota = 'Quota is required*';
        }

        if (!values.category) {
            errors.category = 'Category is required*';
        }

        if (!values.nationality) {
            errors.nationality = 'Nationality is required*';
        }

        if (!values.email) {
            errors.email = 'Email is required*';
        }

        if (!values.contactnumber) {
            errors.contactnumber = 'Contact Number is required*';
        }

        if (!values.emergencycontactnumber) {
            errors.emergencycontactnumber =
                'Emergency Contact Number is required*';
        }

        if (!values.domicle) {
            errors.domicle = 'Domicle is required*';
        }

        if (!values.physicallydisabled) {
            errors.physicallydisabled = 'Physically Disabled is required*';
        }
        return errors;
    };

    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title="Personal info"
                    description="Update your photo and personal details here."
                />
            </div>

            <div className="personal-info">
                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your first name"
                            label="First Name"
                            value={formValues.firstname}
                            onChange={handleChange}
                            error={formErrors.firstname}
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your last name"
                            label="Last Name"
                            value={formValues.lastname}
                            onChange={handleChange}
                            error={formErrors.lastname}
                        />
                    </div>
                </div>

                <div className="personal-info-upload">
                    <div className="personal-info-profile">
                        <img src={Userprofile} alt={'Userprofile'} />
                    </div>
                    <div className="personal-info-uploadfile">
                        <UploadDragDrop />
                    </div>
                </div>

                <div className="about-info">
                    <div className="text-area">
                        <div className="personal-info-about">About</div>
                        <div className="label">
                            <textarea
                                type="text"
                                placeholder="Enter your description"
                            />
                        </div>
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your fathers first name"
                            label="Father's First Name"
                            value={formValues.fathersfirstname}
                            onChange={handleChange}
                            error={formErrors.fathersfirstname}
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your fathers last name"
                            label="Father's Last Name"
                            value={formValues.fatherslastname}
                            onChange={handleChange}
                            error={formErrors.fatherslastname}
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your mothers first name"
                            label="Mother's First Name"
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your mothers last name"
                            label="Mother's Last Name"
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <div className="personal-info-about">Gender</div>
                        <SelectInput
                            placeholder="Select your gender"
                            label="Gender"
                            options={gender}
                            value={formValues.gender}
                            onChange={handleChange}
                            error={formErrors.gender}
                        />
                    </div>

                    <div className="personal-name">
                        <div className="personal-info-about">Blood Group</div>
                        <SelectInput
                            placeholder="Select your blood group"
                            label="Blood Group"
                            options={bloodgroup}
                            value={formValues.bloodgroup}
                            onChange={handleChange}
                            error={formErrors.bloodgroup}
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your institute name"
                            label="Institute Name"
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your date of birth"
                            label="Date of Birth"
                            lefticon={Calendar}
                            value={formValues.dob}
                            onChange={handleChange}
                            error={formErrors.dob}
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <div className="personal-info-about">Quota</div>
                        <SelectInput
                            placeholder="Select your quota"
                            label="Quota"
                            options={quota}
                            value={formValues.quota}
                            onChange={handleChange}
                            error={formErrors.quota}
                        />
                    </div>

                    <div className="personal-name">
                        <div className="personal-info-about">Category</div>
                        <SelectInput
                            placeholder="Select category"
                            label="Category"
                            options={category}
                            value={formValues.category}
                            onChange={handleChange}
                            error={formErrors.category}
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <div className="personal-info-about">Nationality</div>
                        <SelectInput
                            placeholder="Select nationality"
                            label="Nationality"
                            options={nationality}
                            value={formValues.nationality}
                            onChange={handleChange}
                            error={formErrors.nationality}
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your mother tongue"
                            label="Mother Tongue"
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <div className="personal-info-about">
                            Marital Status
                        </div>
                        <SelectInput
                            placeholder="Select marital status"
                            label="Marital Status"
                            options={maritalstatus}
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your email"
                            label="Email"
                            lefticon={Email}
                            value={formValues.email}
                            onChange={handleChange}
                            error={formErrors.email}
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your alternate email address"
                            label="Alternate Email Address"
                            lefticon={Email}
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your contact number"
                            label="Contact Number"
                            lefticon={Call}
                            value={formValues.contactnumber}
                            onChange={handleChange}
                            error={formErrors.contactnumber}
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your alternate contact number"
                            label="Alternate Contact Number"
                            lefticon={Call}
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your emergency contact number"
                            label="Emergency Contact Number"
                            lefticon={Call}
                            value={formValues.emergencycontactnumber}
                            onChange={handleChange}
                            error={formErrors.emergencycontactnumber}
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your name as per certificate"
                            label="Name as per Certificate"
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your domicle"
                            label="Domicle"
                            value={formValues.domicle}
                            onChange={handleChange}
                            error={formErrors.domicle}
                        />
                    </div>
                </div>
                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your PAN card number"
                            label="Pan Card Number"
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your Aadhar card number"
                            label="Aadhar Card Number"
                            value={formValues.aadhar}
                            onChange={handleChange}
                            error={formErrors.aadhar}
                        />
                    </div>
                </div>

                <div className="user-personal-info-data">
                    <div className="personal-name-input">
                        <div className="personal-info-about">
                            Physically Disabled
                        </div>
                        <SelectInput
                            placeholder="Select physically disabled"
                            label="Physically Disabled"
                            options={physicallydisabled}
                            value={formValues.physicallydisabled}
                            onChange={handleChange}
                            error={formErrors.physicallydisabled}
                        />
                    </div>
                </div>

                <div className="bottom-line-personal-info"></div>

                <div className="cb-buttons-userprofile">
                    <ExportButton hover="#F9FAFB" border="none">
                        Skip
                    </ExportButton>
                    <Button
                        width="auto"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                        onClick={handleSave}
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PersonalInfoContent;
