import React from 'react';
import styled from 'styled-components';

const ButtonBase = styled.a`
    width: ${(props) => props.width || '360px'};
    height: ${(props) => props.height || '44px'};
    color: ${(props) => props.color || '#ffffff'};
    background-color: ${(props) => props.backgroundcolor || '#7F56D9'};
    border-radius: 6px;
    border: ${(props) => props.border || '1px solid none'};
    font-size: ${(props) => props.fontsize || '16px'};
    font-weight: 400;
    padding: ${(props) => props.padding || '0px'};
    margin-top: 0px;
    cursor: pointer;
    transition: all 0.6s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    > span {
        font-size: 14px;
        font-weight: 400;
        color: ${(props) => props.color || '#ffffff'};
    }

    > img {
        padding-right: 12px;
    }

    &:hover {
        background: ${(props) => props.hover || '#5D43A5'};
    }
    &:focus {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
    }
    @media only screen and (max-width: 769px) {
        font-size: 16px;
    }
`;

export const LinkButton = ({
    linktext,
    icon,
    width,
    height,
    href,
    backgroundcolor,
    color,
    hover,
    fontsize,
    padding,
    border,
    onClick,
    className,
}) => (
    <ButtonBase
        width={width}
        height={height}
        href={href}
        backgroundcolor={backgroundcolor}
        linktext={linktext}
        color={color}
        hover={hover}
        fontsize={fontsize}
        padding={padding}
        onClick={onClick}
        border={border}
        className={className}
    >
        <img src={icon} />
        <span>{linktext}</span>
    </ButtonBase>
);
