import React from 'react';
import GenericProfileForm from '../../../../Form/GenericProfileForm';

const AddProfessional = ({ onClose, id, scholarId }) => {
    const initialValues = {
        Headline: '',
        OrganizationName: '',
        JobStatus: true,
        StartDate: null,
        EndDate: null,
        CreatedBy: null,
        IsDeleted: false,
        UserProfile: scholarId,
    };

    const fields = [

        {
            type: 'text',
            field: 'OrganizationName',
            label: 'Company Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Department',
            label: 'Department',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Headline',
            label: 'Designation',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'ExperienceType',
            label: 'Experience Type',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'ExperienceType',
            },
        },
        {
            type: 'date',
            field: 'StartDate',
            label: 'StartDate',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
        {
            type: 'date',
            field: 'EndDate',
            label: 'EndDate',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
        {
            type: 'switch',
            field: 'JobStatus',
            label: 'Still Working',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
    ];

    return (
        <div className="userprofile-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Add Experience</h5>
                    <span>Update your experience details here</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onClose}
                    src="/images/cross.svg"
                    alt={'Close Modal'}
                />
            </div>

            <GenericProfileForm
                fields={fields}
                id={id}
                initialValues={initialValues}
                onClose={onClose}
                endpoint={'ProfessionInfo'}
            />
        </div>
    );
};

export default AddProfessional;
