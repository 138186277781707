import React from 'react';
import { FixedInput } from '../../../../../../../../Inputs/FixedInput';
import { FixedInputPlaceholder } from '../../../../../../../../Inputs/FixedInputPlaceholder';
import SelectInput from '../../../../../../../../Inputs/SelectInput';
import { SimpleInput } from '../../../../../../../../Inputs/SimpleInput';

const amount = [{ value: 'amount', label: 'Amount' }];

const FeeWaiver = () => {
    return (
        <>
            <div className="fee-waiver">
                <div className="fee-waiver-content">
                    <div className="fee-waiver-heading">Deliverable 1</div>
                    <FixedInput fixedtext="Deliverable" />
                </div>
                <div className="fee-waiver-content">
                    <div className="fee-waiver-heading">Amount</div>
                    <FixedInputPlaceholder width="40px" fixedtext="₹" />
                </div>
                <div className="fee-waiver-content">
                    <div className="fee-waiver-heading">Fee Waiver Type</div>
                    <SelectInput
                        placeholder="test"
                        options={amount}
                        value={'amount'}
                    />
                </div>
                <div className="fee-waiver-content">
                    <div className="fee-waiver-heading">Fee Waiver </div>
                    <SimpleInput />
                </div>
                <div className="fee-waiver-content">
                    <div className="fee-waiver-heading">After Fee Waiver </div>
                    <FixedInputPlaceholder width="40px" fixedtext="₹" />
                </div>
            </div>
            <div className="bottom-line-fee-waiver"></div>

            <div className="fee-waiver">
                <div className="fee-waiver-content">
                    <div className="fee-waiver-heading">Deliverable 2</div>
                    <FixedInput fixedtext="Deliverable" />
                </div>
                <div className="fee-waiver-content">
                    <div className="fee-waiver-heading">Amount</div>
                    <FixedInputPlaceholder width="40px" fixedtext="₹" />
                </div>
                <div className="fee-waiver-content">
                    <div className="fee-waiver-heading">Fee Waiver Type</div>
                    <SimpleInput />
                </div>
                <div className="fee-waiver-content">
                    <div className="fee-waiver-heading">Fee Waiver </div>
                    <SimpleInput />
                </div>
                <div className="fee-waiver-content">
                    <div className="fee-waiver-heading">After Fee Waiver </div>
                    <FixedInputPlaceholder width="40px" fixedtext="₹" />
                </div>
            </div>
            <div className="bottom-line-fee-waiver"></div>

            <div className="fee-waiver-footer">
                <div className="total-fee">
                    <h4>Total Amount</h4>
                </div>
                <div className="total-amount-fee">
                    <h4>₹</h4>
                </div>
            </div>
        </>
    );
};

export default FeeWaiver;
