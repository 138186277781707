import React from 'react';
import Websitelink from '../../../../../images/website-arrow.svg';
import renderValue from '../../../../../helpers/renderValue';

const renderField = (object, field) => {
    return renderValue(object, { value: field.field, render: field.render });
};

const GeneralView = ({ campus, fields = [] }) => {
    return (
        <div className="view-campusdetails">
            <div className="bottom-line"></div>
            <div className="view-general">
                <div className="general-leftcontent">
                    {fields.map((field) => {
                        return (
                            <div className="view-general-content">
                                {field.label === 'Website' ? (
                                    <>
                                        <span>{field.label}</span>
                                        <p className="view-website-link">
                                            <a
                                                target="_blank"
                                                href={renderField(
                                                    campus,
                                                    field,
                                                )}
                                            >
                                                {renderField(campus, field)}
                                                <img
                                                    className="website-link-icon"
                                                    src={Websitelink}
                                                    alt={Websitelink}
                                                />
                                            </a>
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <span
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {field.label}
                                        </span>
                                        <p>{renderField(campus, field)}</p>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default GeneralView;
