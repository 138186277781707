import React, { useRef } from 'react';
import GenericForm from '../../../../../../../components/Form/GenericForm';
import qs from 'qs';

const AddResearchCentreContent = () => {
    let filters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const initialValues = {
        Name: '',
        Code: '',
        Vision: '',
        Mission: '',
        ClusterId: filters.ClusterId || 0,
        CampusId: filters.CampusId || 0,
        CreatedBy: null,
    };

    const formRef = useRef(null);

    const fields = [
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'Discipline will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
            onChange: (value) => {
                formRef.current.updateData({ ClusterId: '' });
            },
        },
        {
            type: 'select',
            field: 'ClusterId',
            label: 'Select Registry',
            description: 'Discipline will be added to the selected Registry',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
        },
        {
            type: 'text',
            field: 'Name',
            label: 'Discipline Name',
            description: 'Name of the Discipline',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Discipline Code',
            description: 'Unique identifier for the Discipline',
            estric: '*',
        },
        {
            type: 'textarea',
            field: 'Vision',
            label: 'Vision',
            description: 'Vision of the Discipline',
            required: false,
        },
        {
            type: 'textarea',
            field: 'Mission',
            label: 'Mission',
            description: 'Mission of the Discipline',
            required: false,
        },
    ];

    return (
        <GenericForm
            ref={formRef}
            fields={fields}
            initialValues={initialValues}
            endpoint={'Infrastructure/ResearchCenter'}
            redirectTo={'/discipline'}
        />
    );
};

export default AddResearchCentreContent;
