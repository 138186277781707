import React from 'react';
import Sidebar from '../../../../../../components/Sidebar/Sidebar';
import AddScholarContent from './AddScholarContent';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import FormHeading from '../../../../../../components/Heading/FormHeading';
import FormHeader from '../../../../../../components/Header/FormHeader';
import { LinkButton } from '../../../../../../components/Buttons/LinkButton';
import Backarrow from '../../../../../../../images/back-arrow.svg';

const AddScholar = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <div className="heading-button-mobile">
                    <FormHeader
                        homelink="/dashboard"
                        breadcrumb="Scholars"
                        breadcrumbs="Add Scholar"
                        innerpath="/scholar"
                    />
                    <FormHeading
                        title="Add Scholar"
                        description="Enter the following details to add scholar"
                    />
                    <MediaQuery maxWidth={1023}>
                        <div className='back-button'>
                        <LinkButton
                            linktext="Back to Scholars"
                            icon={Backarrow}
                            href="/scholar"
                            width="204px"
                            height="44px"
                            backgroundcolor="none"
                            color="#5D43A5"
                            fontsize="16px"
                            hover="none"
                            padding="20px"
                            className="view-back-button"
                        />
                        </div>
                    </MediaQuery>
                    <AddScholarContent />
                </div>
            </div>
        </div>
    );
};

export default AddScholar;
