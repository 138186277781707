import React from 'react';
import { Link } from 'react-router-dom';
import renderValue from '../../../helpers/renderValue';
import Pagination from '../Pagination/Pagination';

const NoRecordsFound = () => {
    return (
        <div className="no-records-incard">
            <span>No Records Found</span>
        </div>
    );
};

const renderField = (item, col, renderColumn) => {
    const rendered = renderValue(item, col);
    if (renderColumn) {
        return renderColumn(item, col, rendered);
    }
    return rendered;
};

const Card = ({
    column,
    data,
    renderColumn,
    onDelete,
    onEdit,
    onView,
    isLoading,
    Loading,
    editName,
    onAssignDeliverable,

    shouldView = true,
    shouldDelete = true,
    shouldEdit = true,
    shouldAssignDeliverable = true,
}) => {
    return (
        <div>
            {data.map((item) => (
                <div className="card">
                    <div className="card-info">
                        {column.map((col) => {
                            return (
                                <div className="card-info-content">
                                    <span>{col.heading}</span>
                                    <p className="card-right-info">
                                        {renderField(item, col, renderColumn)}
                                    </p>
                                </div>
                            );
                        })}
                    </div>

                    <div className="action-bottom">
                        <div className="bottom-line-card"></div>

                        <div className="card-actions">
                            {shouldView && (
                                <Link
                                    className="card-action-edit"
                                    to="#"
                                    onClick={() => {
                                        onView(item);
                                    }}
                                >
                                    View
                                </Link>
                            )}
                            {shouldEdit && (
                                <Link
                                    className="card-action-edit"
                                    to="#"
                                    onClick={() => {
                                        onEdit(item);
                                    }}
                                >
                                    {editName || 'Edit'}
                                </Link>
                            )}
                            {shouldDelete && (
                                <Link
                                    className="card-action-delete"
                                    to="#"
                                    onClick={() => {
                                        onDelete(item);
                                    }}
                                >
                                    Delete
                                </Link>
                            )}
                            {shouldAssignDeliverable && (
                                <Link
                                    className="action-assign-deliverable"
                                    to="#"
                                    onClick={() => {
                                        onAssignDeliverable(item);
                                    }}
                                >
                                    Assign Sub Deliverable
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            ))}
            {(!data || data.length === 0) && !isLoading && <NoRecordsFound />}
            {isLoading && <Loading/>}
        </div>
    );
};

export default Card;
