import React, { useState } from 'react';
import Campus from '../../../../../images/campus.svg';
import classNames from 'classnames';
import SidebarActiveIcon from '../../../Sidebar Active Icon/SidebarActiveIcon';
import Campusactive from '../../../../../images/campus-active.svg';
import Infrastructureactive from '../../../../../images/infrastructure-active.svg';
import Infrastructure from '../../../../../images/infrastructure.svg';
import Academicactive from '../../../../../images/academic-active.svg';
import Academic from '../../../../../images/academic.svg';
import DRCactive from '../../../../../images/drc-active.svg';
import DRC from '../../../../../images/drc.svg';
import Supervisorsactive from '../../../../../images/supervisors-active.svg';
import Supervisors from '../../../../../images/supervisors.svg';
import Useractive from '../../../../../images/user-active.svg';
import Users from '../../../../../images/users.svg';
import Feeactive from '../../../../../images/fee-active.svg';
import Fee from '../../../../../images/fee.svg';
import ActiveDropdown from '../../../../../images/arrow-active.svg';
import InactiveDropdown from '../../../../../images/dropdown.svg';

const getActiveMenuIndex = (url) => {
    let parts = url.split('/');
    switch (parts[3]) {
        case 'campus':
        case 'department':
            return 10;
        case 'registry':
        case 'discipline':
            return 1;
        case 'academic-year':
        case 'batch':
        case 'program':
        case 'specialization':
        case 'milestone':
        case 'deliverable':
        case 'sub-deliverable':
            return 2;
        case 'staff':
            return 5;
        case 'supervisor':
            return 4;
        case 'fee-policy':
        case 'assign-fee-policy':
            return 6;
        case 'promote-scholar':
        case 'scholar':
        case 'assign-deliverables':
        case 'assign-supervisors':
            return 3;
        default:
            return 1;
    }
};

const getActiveModule = (url) => {
    let parts = url.split('/');
    return parts[3];
};

const MobileNavigation = () => {
    const [showDrop, setShowDrop] = useState(
        getActiveMenuIndex(window.location.href),
    );
    const getClassNames = (defaultClass, active) => {
        return classNames(defaultClass, { active: active });
    };

    const activeModule = getActiveModule(window.location.href);

    return (
        <div className="sidebar-mobile">
            <ul className="main-navigation">
                <li
                    onClick={() => setShowDrop(showDrop === 10 ? null : 10)}
                    className={getClassNames('nav-item', showDrop === 10)}
                >
                    <SidebarActiveIcon
                        className="nav-link"
                        active={Campusactive}
                        inactive={Campus}
                        url=""
                        tabname="Campus Setup"
                        activearrow={ActiveDropdown}
                        inactivearrow={InactiveDropdown}
                    />

                    {showDrop === 10 && (
                        <ul className="list-drop-down">
                            <li>
                                <SidebarActiveIcon
                                    isActive={activeModule === 'campus'}
                                    className="nav-link"
                                    url="/campus"
                                    tabname="Campuses"
                                />
                            </li>
                        </ul>
                    )}
                </li>

                <li
                    onClick={() => setShowDrop(showDrop === 1 ? null : 1)}
                    className={getClassNames('nav-item', showDrop === 1)}
                >
                    <SidebarActiveIcon
                        className="nav-link"
                        active={Infrastructureactive}
                        inactive={Infrastructure}
                        url=""
                        tabname="Infrastructure"
                        activearrow={ActiveDropdown}
                        inactivearrow={InactiveDropdown}
                    />

                    {showDrop === 1 && (
                        <ul className="list-drop-down">
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/registry"
                                    tabname="Registries"
                                />
                            </li>
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/discipline"
                                    tabname="Disciplines"
                                />
                            </li>
                        </ul>
                    )}
                </li>

                <li
                    onClick={() => setShowDrop(showDrop === 2 ? null : 2)}
                    className={getClassNames('nav-item', showDrop === 2)}
                >
                    <SidebarActiveIcon
                        className="nav-link"
                        active={Academicactive}
                        inactive={Academic}
                        url=""
                        tabname="Academics Setup"
                        activearrow={ActiveDropdown}
                        inactivearrow={InactiveDropdown}
                    />

                    {showDrop === 2 && (
                        <ul className="list-drop-down">
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/academic-year"
                                    tabname="Academic Years"
                                />
                            </li>
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/batch"
                                    tabname="Batches"
                                />
                            </li>
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/program"
                                    tabname="Programs"
                                />
                            </li>
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/specialization"
                                    tabname="Specializations"
                                />
                            </li>
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/deliverable"
                                    tabname="Deliverables"
                                />
                            </li>
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/sub-deliverable"
                                    tabname="Sub Deliverables"
                                />
                            </li>
                        </ul>
                    )}
                </li>
                <li
                    onClick={() => setShowDrop(showDrop === 5 ? null : 5)}
                    className={getClassNames('nav-item', showDrop === 5)}
                >
                    <SidebarActiveIcon
                        className="nav-link"
                        active={DRCactive}
                        inactive={DRC}
                        url=""
                        tabname="DRC Faculty"
                        activearrow={ActiveDropdown}
                        inactivearrow={InactiveDropdown}
                    />

                    {showDrop === 5 && (
                        <ul className="list-drop-down">
                            <li>
                                <SidebarActiveIcon
                                    stopPropagation={true}
                                    className="nav-link"
                                    url="/staff"
                                    tabname="DRC Faculty"
                                />
                            </li>
                        </ul>
                    )}
                </li>
                <li
                    onClick={() => setShowDrop(showDrop === 4 ? null : 4)}
                    className={getClassNames('nav-item', showDrop === 4)}
                >
                    <SidebarActiveIcon
                        className="nav-link"
                        active={Supervisorsactive}
                        inactive={Supervisors}
                        url=""
                        tabname="Supervisors"
                        activearrow={ActiveDropdown}
                        inactivearrow={InactiveDropdown}
                    />

                    {showDrop === 4 && (
                        <ul className="list-drop-down">
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/supervisor"
                                    tabname="Supervisors"
                                />
                            </li>
                            {/*<li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/default-invite-Supervisor"
                                    tabname="Invite Supervisor"
                                />
                            </li>*/}
                        </ul>
                    )}
                </li>
                <li
                    onClick={() => setShowDrop(showDrop === 3 ? null : 3)}
                    className={getClassNames('nav-item', showDrop === 3)}
                >
                    <SidebarActiveIcon
                        className="nav-link"
                        active={Useractive}
                        inactive={Users}
                        url=""
                        tabname="Scholars"
                        activearrow={ActiveDropdown}
                        inactivearrow={InactiveDropdown}
                    />

                    {showDrop === 3 && (
                        <ul className="list-drop-down">
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/scholar"
                                    tabname="Scholars"
                                />
                            </li>
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/assign-deliverables"
                                    tabname="Assign Sub Deliverables"
                                />
                            </li>
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/assign-supervisors"
                                    tabname="Assign Supervisor"
                                />
                            </li>
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/promote-scholar"
                                    tabname="Promote Scholar"
                                />
                            </li>
                        </ul>
                    )}
                </li>
                <li
                    onClick={() => setShowDrop(showDrop === 6 ? null : 6)}
                    className={getClassNames('nav-item', showDrop === 6)}
                >
                    <SidebarActiveIcon
                        className="nav-link"
                        active={Feeactive}
                        inactive={Fee}
                        url=""
                        tabname="Fees"
                        activearrow={ActiveDropdown}
                        inactivearrow={InactiveDropdown}
                    />

                    {showDrop === 6 && (
                        <ul className="list-drop-down">
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/fee-policy"
                                    tabname="Fee Policy"
                                />
                            </li>
                            <li>
                                <SidebarActiveIcon
                                    className="nav-link"
                                    url="/assign-fee-policy"
                                    tabname="Assign Fee Policy"
                                />
                            </li>
                        </ul>
                    )}
                </li>
                {/* <MobileFooter /> */}
            </ul>
        </div>
    );
};

export default MobileNavigation;
