import React from 'react';
import styled from 'styled-components';
import Loader from 'react-js-loader';

const ButtonBase = styled.button`
    width: ${(props) => props.width || '360px'};
    height: ${(props) => props.height || '44px'};
    color: ${(props) => props.color || '#ffffff'};
    background-color: ${(props) => props.backgroundcolor || '#7F56D9'};
    border-radius: 8px;
    border: ${(props) => props.border || 'none'};
    font-size: 16px;
    font-weight: ${(props) => props.fontweight || '300'};
    margin-top: ${(props) => props.margintop || '0px'};
    margin-right: ${(props) => props.marginright || '0px'};
    padding: ${(props) => props.padding || '0px'};
    cursor: pointer;
    transition: all 0.6s;

    &:hover {
        background: ${(props) => props.hover || '#ffffff'};
    }
    &:focus {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
    }
    @media only screen and (max-width: 769px) {
        padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
        font-size: 16px;
    }
`;

export const Button = ({
    onClick,
    disabled,
    children,
    loading,
    width,
    height,
    backgroundcolor,
    fontweight,
    border,
    hover,
    color,
    margintop,
    marginright,
    padding,
    type
}) => (
    <ButtonBase
        type={type}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        width={width}
        height={height}
        backgroundcolor={backgroundcolor}
        fontweight={fontweight}
        border={border}
        hover={hover}
        color={color}
        margintop={margintop}
        marginright={marginright}
        padding={padding}
    >
        {loading ? (
            <>
                <Loader
                    type="spinner-default"
                    color="#FFFFFF"
                    bgColor="#ffffff"
                    size={34}
                />
            </>
        ) : (
            children
        )}
    </ButtonBase>
);
