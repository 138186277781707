import React from 'react';
import Header from '../../../../../../../components/Header/Header';
import Heading from '../../../../../../../components/Heading/Heading';
import Progressbar from '../../../../../../../components/Progress Bar/Progressbar';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import MediaQuery from 'react-responsive';
import GenericContent from '../Generic/GenericContent';
import { useParams } from 'react-router-dom';

const AwardDetail = () => {
    let { scholarId } = useParams();
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    title="Scholars"
                    breadcrumbs="My Profile"
                    href="/scholar"
                />
                <Heading title="My Profile" description="" />
                <div className="user-profile-section">
                    <Progressbar />
                    <GenericContent
                        id={scholarId}
                        endpoint={'Award'}
                        column={[
                            {
                                heading: 'Title',
                                value: 'Title',
                            },
                            {
                                heading: 'Year',
                                value: 'Year',
                            },
                            {
                                heading: 'Issuer',
                                value: 'Issuer',
                            },
                            {
                                heading: 'Date',
                                value: 'Date.date',
                            },
                        ]}
                        fields={[]}
                        initialValues={{
                            Title: 'Title',
                            Issuer: 'Issuer',
                            Description: 'Description',
                            Date: null,
                            Year: 2022,
                            CreatedAt: null,
                            CreatedBy: null,
                            IsDeleted: false,
                            UserProfile: scholarId,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default AwardDetail;
