import React, { useState } from 'react';
import { Button } from '../../../../Buttons/Button';
import { ExportButton } from '../../../../Buttons/ExportButton';
import SelectInput from '../../../../Inputs/SelectInput';
import { SimpleInput } from '../../../../Inputs/SimpleInput';
import Calendar from '../../../../../../images/calendar.svg';

const options = [
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' },
];

const AddPublication = (props) => {
    const initialValues = {
        title: '',
        publisher: '',
        year: '',
        publicationurl: '',
        description: '',
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (event) => {
        let i = { ...formValues };
        i[event.target.name] = event.target.value;
        setFormValues(i);
    };

    const handleSave = (e) => {
        e.preventDefault();
        let errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            handleSave({ ...formValues });
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.title) {
            errors.title = 'Title name is required*';
        }

        if (!values.publisher) {
            errors.publisher = 'Publisher is required*';
        }

        if (!values.year) {
            errors.year = 'Year is  required*';
        }

        if (!values.publicationurl) {
            errors.publicationurl = 'Publication URL is  required*';
        }

        if (!values.description) {
            errors.description = 'Description is required*';
        }
        return errors;
    };
    return (
        <div className="userprofile-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Add Publication</h5>
                    <span>Update your publication details here</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={props.onClose}
                    src="/images/cross.svg"
                />
            </div>

            <div className="invite-supervisor-content">
                <div className="invite-supervisor">
                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter title"
                                label="Title*"
                                value={formValues.title}
                                onChange={handleChange}
                                error={formErrors.title}
                            />
                        </div>
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter publisher"
                                label="Publisher*"
                                value={formValues.publisher}
                                onChange={handleChange}
                                error={formErrors.publisher}
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter publication date"
                                label="Publication Date"
                                lefticon={Calendar}
                            />
                        </div>
                        <div className="supervisor-name">
                            <div className="personal-info-about">Year*</div>
                            <SelectInput
                                placeholder="Select year"
                                label="Status"
                                options={options}
                                value={formValues.year}
                                onChange={handleChange}
                                error={formErrors.year}
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder=" Enter Author(s)"
                                label="Author(s)"
                            />
                        </div>
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter publication URL"
                                label="Publication URL*"
                                value={formValues.publicationurl}
                                onChange={handleChange}
                                error={formErrors.publicationurl}
                            />
                        </div>
                    </div>

                    <div className="userprofile-textarea ">
                        <div className="userprofile-textarea-input">
                            <div className="modal-text-area-label">
                                Description
                            </div>
                            <div className="text-area">
                                <textarea
                                    placeholder="Enter description"
                                    value={formValues.description}
                                    onChange={handleChange}
                                    error={formErrors.description}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cb-buttons-userprofile">
                    <ExportButton hover="#F9FAFB" border="1px solid #D0D5DD">
                        Cancel
                    </ExportButton>
                    <Button
                        width="auto"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddPublication;
