import React, { useState } from 'react';
import useListApi from '../../../../../../../../hooks/useListApi';
import { useNavigate } from 'react-router-dom';
import Heading from '../../../../../../../components/Heading/Heading';
import Plus from '../../../../../../../../images/plus.svg';
import { Modal } from 'react-bootstrap';
import AddAward from '../../../../../../../components/Modals/User Profile Modals/Subcomponents/Add Award Details/AddAward';
import ProfileTable from '../../../../../../../components/Table/ProfileTable';

const AwardDetailContent = () => {
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Campus');

    const navigate = useNavigate();

    const [objectId, setObjectId] = useState(null);
    const [userprofileModal, setuserprofileModal] = useState(false);
    const userprofileModalClose = () => {
        setuserprofileModal(false);
        refresh();
    };
    const userprofileModalShow = () => setuserprofileModal(true);

    const column = [
        {
            heading: 'Title',
            value: 'Title',
        },
        {
            heading: 'Year',
            value: 'Year',
        },
        {
            heading: 'Issuer',
            value: 'Issuer',
        },
        {
            heading: 'Date',
            value: 'Date.date',
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'cluster') {
            return (
                <td>
                    <a href={`/registry?CampusId=${row.CampusId}`}>Registry</a>
                </td>
            );
        }
        return rendered;
    };

    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title="Awards"
                    description="Upload your awards details here."
                    linktext="Add More"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    onClick={userprofileModalShow}
                />
            </div>

            <ProfileTable
                shouldView={false}
                checkboxshouldView={false}
                resource={'Campus'}
                refresh={refresh}
                data={rows}
                totalRecords={totalRecords}
                perPage={recordsPerPage}
                setPerPage={setRecordsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                column={column}
                renderColumn={renderColumn}
                tableHeading="All Awards"
                onEdit={(item) => {
                    setObjectId(item.id);
                    setuserprofileModal(true);
                }}
            />

            {/* Modal */}

            <Modal
                show={userprofileModal}
                onHide={userprofileModalClose}
                centered
                className="userprofileModal"
            >
                <AddAward
                    onShow={userprofileModal}
                    onClose={userprofileModalClose}
                />
            </Modal>
        </div>
    );
};

export default AwardDetailContent;
