import React from 'react';
import Header from '../../../../../../../components/Header/Header';
import Heading from '../../../../../../../components/Heading/Heading';
import Progressbar from '../../../../../../../components/Progress Bar/Progressbar';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import ProfessionalDetailContent from './ProfessionalDetailContent';
import { useParams } from 'react-router-dom';

const ProfessionalDetail = () => {
    const { scholarId } = useParams();
    return (
        <div className="main-wrapper">
            <Sidebar />
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    title="Scholars"
                    breadcrumbs="My Profile"
                    href="/scholar"
                />
                <Heading title="My Profile" description="" />
                <div className="user-profile-section">
                    <Progressbar />
                    <ProfessionalDetailContent id={scholarId} />
                </div>
            </div>
        </div>
    );
};

export default ProfessionalDetail;
