import React, { useRef } from 'react';
import GenericForm from '../../../../../../../../../components/Form/GenericForm';
import qs from 'qs';

const AddResearchCentreContent = () => {
    let filters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const initialValues = {
        Name: '',
        Code: '',
        Description: '',
        Contact: '',
        ClusterId: filters.ClusterId || 0,
        CampusId: filters.CampusId || 0,
        ResearchCenterId: filters.ResearchCenterId || 0,
    };

    const formRef = useRef(null);
    const fields = [
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'Program will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ClusterId: '',
                    ResearchCenterId: '',
                });
            },
        },
        {
            type: 'select',
            field: 'ClusterId',
            label: 'Select Registry',
            description: 'Program will be added to the selected Registry',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({ ResearchCenterId: '' });
            },
        },
        {
            type: 'select',
            field: 'ResearchCenterId',
            label: 'Select Discipline',
            description: 'Program will be added to the selected Discipline',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
        },
        {
            type: 'text',
            field: 'Name',
            label: 'Program name',
            description: 'Name of the program',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Program Code',
            description: ' Unique identifier for the program',
            estric: '*',
        },
        {
            type: 'textarea',
            field: 'Description',
            label: 'Program Description',
            description: 'Detailed description of the program',
            required: false,
        },
        {
            type: 'text',
            field: 'Contact',
            label: 'Contact Person',
            description:
                'Name and phone number of the someone to be contacted for program-related queries',
            required: false,
        },
    ];

    return (
        <GenericForm
            ref={formRef}
            fields={fields}
            initialValues={initialValues}
            endpoint={'AcademicStructure/Program'}
            redirectTo={'/program'}
        />
    );
};

export default AddResearchCentreContent;
