import React, { useRef, useState } from 'react';
import useListApi from '../../../../../hooks/useListApi';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import Header from '../../../../components/Header/Header';
import Heading from '../../../../components/Heading/Heading';
import Table from '../../../../components/Table/Table';
import Plus from '../../../../../images/plus.svg';
import PlusIcon from '../../../../../images/plus.svg';
import ImportPlus from '../../../../../images/importplus.svg';
import MobileHeader from '../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import MediaQuery from 'react-responsive';
import HeaderFilters from '../../../../components/Filters/HeaderFilters';
import ExportIcon from '../../../../../images/export.svg';

const DRCFacultyList = () => {
    const [filters, setFilters] = useState({});
    const {
        rows,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
        refresh,
    } = useListApi('Users/Staff', false, true, filters);

    const column = [
        {
            heading: 'Name',
            render: (item) => {
                return `${item.FirstName} ${item.LastName}`;
            },
        },
        {
            heading: 'Employee Id',
            value: 'EmployeeId',
        },
        {
            heading: 'Designation',
            value: 'Designation',
        },
        {
            heading: 'Registry',
            value: 'ClusterName',
        },
        {
            heading: 'Discipline',
            value: 'ResearchCenterName',
        }
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'personalinfo') {
            return (
                <td>
                    <a href={`/personalinfo?ClusterId=${row.ClusterId}`}>
                        User Profile
                    </a>
                </td>
            );
        }
        return rendered;
    };

    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header homelink="/dashboard" breadcrumbs="Staff" />
                <Heading
                    title="Staff"
                    description="List of Staff"
                    linktext="Import Staff"
                    icon={Plus}
                    plusicon={PlusIcon}
                    className="import-staff"
                    width="auto"
                    height="40px"
                    padding="0px 10px !important"
                    href="/import-staff"
                    importtext="Add Staff"
                    importonClick={`/staff/new?ClusterId=${filters.ClusterId}&CampusId=${filters.CampusId}&ResearchCenterId=${filters.ResearchCenterId}`}
                    importicon={ImportPlus}
                    tableRef={tableRef}
                    exporticon={ExportIcon}
                />
                <HeaderFilters
                    filters={filters}
                    availableFilter={['campus', 'cluster', 'researchCenter','IsFaculty','FacultyType']}
                    setFilters={setFilters}
                />
                <Table
                    ref={tableRef}
                    shouldView={true}
                    shouldAssignFee={false}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    refresh={refresh}
                    renderColumn={renderColumn}
                    resource={'Users/DRCFaculty'}
                    tableHeading="All Staff"
                    name="Staff"
                    multipleDelete="Staff"
                    onEdit={(item) => {
                        window.location.href = `/staff/${item.id}/edit`;
                    }}
                    onView={(item) => {
                        window.location.href = `/staff/${item.id}/view`;
                    }}
                />
            </div>
        </div>
    );
};

export default DRCFacultyList;
