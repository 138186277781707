import React from 'react';
import MobileHeader from '../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../components/Sidebar/Sidebar';
import AddClusterContent from './AddClusterContent';
import MediaQuery from 'react-responsive';
import FormHeading from '../../../../../components/Heading/FormHeading';
import FormHeader from '../../../../../components/Header/FormHeader';
import { LinkButton } from '../../../../../components/Buttons/LinkButton';
import Backarrow from '../../../../../../images/back-arrow.svg';

const AddClusters = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <div className="heading-button-mobile">
                    <FormHeader
                        homelink="/dashboard"
                        title="Campuses"
                        breadcrumb="Registries"
                        breadcrumbs="Add Registry"
                        href="/dashboard"
                        innerpath="/registry"
                    />
                    <FormHeading
                        title="Add Registry"
                        description="Enter the following details to add registry"
                    />
                    <MediaQuery maxWidth={1023}>
                        <div className="back-button">
                            <LinkButton
                                linktext="Back to Registries"
                                icon={Backarrow}
                                href="/registry"
                                width="204px"
                                height="44px"
                                backgroundcolor="none"
                                color="#5D43A5"
                                fontsize="16px"
                                hover="none"
                            />
                        </div>
                    </MediaQuery>
                    <AddClusterContent />
                </div>
            </div>
        </div>
    );
};

export default AddClusters;
