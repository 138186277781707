import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
    width: 100%;
    float: left;

    > label {
        font-size: 14px;
        font-weight: 400;
        color: #344054;
        padding-bottom: 4px;
    }

    > span {
        font-size: 14px;
        color: #f04438;
    }
`;

const GroupContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    border: 1px solid ${(props) => (props.hasError ? '#F04438' : '#D0D5DD')};
    border-top-right-radius: 0px !importatnt;
    border-bottom-right-radius: 0px !importatnt;
    border-top-left-radius: 0px !importatnt;
    border-bottom-left-radius: 0px !importatnt;
    border-radius: 8px;
    padding: 0 0px;

    &:hover {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
    }

    > img {
        width: 18px;
        height: 18px;
        margin: 8px;
        color: #c1c1c1;
    }
`;

const PlaceholderFixed = styled.div`
    width: ${(props) => props.width || '160px'};
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #d0d5dd;
    padding: 14px;
    color: #667085;
`;

const InputFixed = styled.div`
    width: 'auto';
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 18px;
    color: #667085;
`;

export const FixedInputPlaceholder = ({
    error,
    successMsg,
    fixedtext,
    width,
    fixedinputtext,
}) => (
    <InputContainer>
        <GroupContainer hasError={error}>
            <PlaceholderFixed width={width}>{fixedtext}</PlaceholderFixed>
            <InputFixed width={width}>{fixedinputtext}</InputFixed>
        </GroupContainer>
        {successMsg ? (
            <p style={{ color: 'green' }}>{successMsg}</p>
        ) : (
            <span>{error}</span>
        )}
    </InputContainer>
);
