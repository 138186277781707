import React, { useRef } from 'react';
import qs from 'qs';
import GenericForm from '../../../../../../components/Form/GenericForm';

const AddScholarContent = () => {
    let filters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const initialValues = {
        UniversityRollNo: '',
        CampusId: filters.CampusId || '',
        ClusterId: filters.ClusterId || '',
        ResearchCenterId: filters.ResearchCenterId || '',
        BatchId: filters.BatchId || '',
        ProgramId: filters.ProgramId || '',
        DisciplineId: filters.DisciplineId || '',
        Honorifics: '',
        FirstName: '',
        Lastname: '',
        Gender: '',
        Email: '',
        PhoneNumber: '',
        MotherName: '',
        FatherName: '',
        BirthDate: '',
        Domicile: '',
        Category: '',
        Quota: '',
        City: '',
        Nationality: '',
        CurrentAddress: '',
        PermanentAddress: '',
        ProfilePhoto: '',
        AadharCardNumber: '',
        PanCardNumber: '',
    };

    const formRef = useRef(null);

    const fields = [
        {
            type: 'file',
            fileType: 'image/*',
            field: 'ProfilePhoto',
            required: false,
            fullWidth: true,
        },
        {},
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ClusterId: '',
                    ResearchCenterId: '',
                    MilestoneId: '',
                });
            },
        },
        {
            type: 'select',
            field: 'ClusterId',
            label: 'Select Registry',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ResearchCenterId: '',
                    BatchId: '',
                    ProgramId: '',
                    DisciplineId: '',
                });
            },
        },
        {
            type: 'select',
            field: 'ResearchCenterId',
            label: 'Select Discipline',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({
                    BatchId: '',
                    ProgramId: '',
                    DisciplineId: '',
                });
            },
        },
        {
            type: 'select',
            field: 'BatchId',
            label: 'Select Batch',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'AcademicStructure/Batch',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return (
                        cluster.ResearchCenterId === formValue.ResearchCenterId
                    );
                },
            },
            onChange: (value) => {},
        },
        {
            type: 'select',
            field: 'ProgramId',
            label: 'Select Program',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'AcademicStructure/Program',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return (
                        cluster.ResearchCenterId === formValue.ResearchCenterId
                    );
                },
            },
            onChange: (value) => {
                formRef.current.updateData({ DisciplineId: '' });
            },
        },
        {
            type: 'select',
            field: 'DisciplineId',
            label: 'Select Specialization',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'AcademicStructure/Discipline',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ProgramId === formValue.ProgramId;
                },
            },
            onChange: (value) => {},
        },
        {
            type: 'text',
            field: 'UniversityRollNo',
            label: 'University Roll No',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Honorifics',
            label: 'Title',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Honorifics',
            },
        },

        {
            type: 'text',
            field: 'FirstName',
            label: 'First Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'LastName',
            label: 'Last Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Gender',
            label: 'Gender',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Gender',
            },
        },
        {
            type: 'text',
            field: 'Email',
            label: 'Email',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PhoneNumber',
            label: 'Phone Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'MotherName',
            label: 'Mother Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'FatherName',
            label: 'Father Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'date',
            field: 'BirthDate',
            label: 'Date of Birth',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Domicile',
            label: 'Domicile',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Domicile',
            },
        },
        {
            type: 'select',
            field: 'Category',
            label: 'Category',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Category',
            },
        },
        {
            type: 'select',
            field: 'Quota',
            label: 'Quota',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Quota',
            },
        },
        {
            type: 'text',
            field: 'City',
            label: 'City',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Nationality',
            label: 'Nationality',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PanCardNumber',
            label: 'Pan Card Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'AadharCardNumber',
            label: 'Aadhar Card Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'textarea',
            field: 'CurrentAddress',
            label: 'Current Address',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'textarea',
            field: 'PermanentAddress',
            label: 'Permanent Address',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
    ];

    return (
        <GenericForm
            twoColumns={true}
            ref={formRef}
            fields={fields}
            initialValues={initialValues}
            endpoint={'Users/Scholar'}
            redirectTo={'/scholar'}
        />
    );
};

export default AddScholarContent;
