import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import AssignedCompleted from '../../../../../../../../images/completed.svg';
import CurrentAssigned from '../../../../../../../../images/current-status.svg';
import InactiveAssigned from '../../../../../../../../images/inactive-assign.svg';
import Heading from '../../../../../../../components/Heading/Heading';
import CustomCheckbox from '../../../../../../../components/Inputs/CustomCheckbox';
import Api from '../../../../../../../../api/api';
import * as PropTypes from 'prop-types';
import { ExportButton } from '../../../../../../../components/Buttons/ExportButton';
import { Button } from '../../../../../../../components/Buttons/Button';
import Loader from 'react-js-loader';

function RenderSubDeliverables({ milestone, editable, id }) {
    const [deliverables, setDeliverables] = useState([]);
    const [assignedDeliverables, setAssignedDeliverables] = useState([]);

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        setDeliverables([]);
        setAssignedDeliverables([]);
        Api.get(
            `/AcademicStructure/ScholarDeliverable/getEntities?PerPage=5&PageNo=1&ScholarId=${id}`,
        )
            .then((response) => {
                setAssignedDeliverables(
                    response.Data.map(({ Deliverable }) => {
                        return parseInt(Deliverable.id);
                    }),
                );
            })
            .catch(console.log);
        Api.get(
            `/AcademicStructure/Deliverable/getEntities?MilestoneId=${milestone.id}`,
        )
            .then((response) => {
                setDeliverables(response.Data);
                setLoading(false);
            })
            .catch(console.log);
    }, [id, milestone]);

    const submit = () => {
        setLoading(true);
        Api.post(
            '/AcademicStructure/ScholarDeliverable/AssignDeliverables',
            assignedDeliverables.map((deliverable) => {
                return {
                    ScholarId: id,
                    DeliverableId: parseInt(deliverable),
                };
            }),
        )
            .then(() => {})
            .catch(console.log)
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="assign-deliverable-screen">
            <div className="profile-heading">
                <Heading
                    title={milestone.Title}
                    description="Update your sub deliverable details here"
                />
            </div>
            <div className="deliverables-content-left">
                {loading && (
                    <div style={{ marginTop: 50 }}>
                        <Loader
                            type="spinner-default"
                            color="#FFFFFF"
                            bgColor="#7f56d9"
                            size={48}
                        />
                    </div>
                )}
                {!loading &&
                    deliverables.map((deliverable) => {
                        return (
                            <div className="deliverables-content-right-top">
                                <div className="deliverable-content-right">
                                    <CustomCheckbox
                                        disabled={!editable}
                                        onChange={() => {
                                            if (
                                                assignedDeliverables.indexOf(
                                                    parseInt(deliverable.id),
                                                ) === -1
                                            ) {
                                                setAssignedDeliverables([
                                                    ...assignedDeliverables,
                                                    parseInt(deliverable.id),
                                                ]);
                                            } else {
                                                let dvs = [
                                                    ...assignedDeliverables,
                                                ];
                                                dvs.splice(
                                                    dvs.indexOf(
                                                        parseInt(
                                                            deliverable.id,
                                                        ),
                                                    ),
                                                    1,
                                                );
                                                setAssignedDeliverables(dvs);
                                            }
                                        }}
                                        isChecked={
                                            assignedDeliverables.indexOf(
                                                parseInt(deliverable.id),
                                            ) > -1
                                        }
                                    />
                                    <div className="deliverable-subcontent-right">
                                        <span>{deliverable.Title}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            {!loading && editable && (
                <div className="cb-buttons-deliverable">
                    <ExportButton
                        hover="#f9fafb"
                        onClick={() => {
                            window.history.back();
                        }}
                    >
                        Cancel
                    </ExportButton>
                    <Button
                        disabled={loading}
                        width="auto"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                        onClick={submit}
                    >
                        {loading ? 'Please wait..' : 'Save'}
                    </Button>
                </div>
            )}
        </div>
    );
}

RenderSubDeliverables.propTypes = {
    milestone: PropTypes.number,
    editable: PropTypes.bool,
};
const AssignDeliverableContent = () => {
    const [scholar, setScholar] = useState(null);
    const [deliverables, setDeliverables] = useState([]);
    const [milestones, setMilestones] = useState([]);
    const [currentMilestone, setCurrentMilestone] = useState(null);

    let { id } = useParams();

    const refresh = () => {
        Api.get(`/Users/Scholar/getEntity/${id}`)
            .then(setScholar)
            .catch(console.log);
    };

    useEffect(() => {
        if (!scholar) {
            return;
        }

        Api.get(
            `/AcademicStructure/Milestone/getEntities?ResearchCenterId=${scholar.ResearchCenterId}`,
        )
            .then((response) => {
                let currentMilestone = response.Data.find((milestone) => {
                    return scholar.MilestoneTitle === milestone.Title;
                });
                setCurrentMilestone(currentMilestone);
                let currentMilestoneOrder = currentMilestone
                    ? parseInt(currentMilestone.MilestoneOrder)
                    : 0;
                let milestones = response.Data.reverse().map((milestone) => {
                    let ret = { ...milestone };
                    if (
                        parseInt(milestone.MilestoneOrder) <
                        currentMilestoneOrder
                    ) {
                        ret.status = 'completed';
                    }
                    if (
                        parseInt(milestone.MilestoneOrder) ===
                        currentMilestoneOrder
                    ) {
                        ret.status = 'current';
                    }
                    if (
                        parseInt(milestone.MilestoneOrder) >
                        currentMilestoneOrder
                    ) {
                        ret.status = 'next';
                    }
                    return ret;
                });

                setMilestones(milestones);
            })
            .catch(console.log);
    }, [scholar]);

    useEffect(() => {
        refresh();
    }, []);

    const [checked, setChecked] = useState(false);
    const [selectedMilestone, setSelectedMilestone] = useState(null);

    const getStatusImage = (status) => {
        switch (status) {
            case 'current':
                return CurrentAssigned;
            case 'next':
                return InactiveAssigned;
            case 'completed':
                return AssignedCompleted;
        }
    };
    return (
        <div className="assign-deliverable-progress-bar">
            <div className="progress-bar-assign">
                <div className="progress-bar-content-assign">
                    {milestones.map((milestone) => {
                        return (
                            <ul>
                                <li>
                                    <Link
                                        to="#"
                                        onClick={() => {
                                            setSelectedMilestone(milestone);
                                        }}
                                    >
                                        <div className="assign-info-tab">
                                            <img
                                                src={getStatusImage(
                                                    milestone.status,
                                                )}
                                            />
                                            <span>{milestone.Title}</span>
                                        </div>
                                    </Link>
                                    <div className="assign-info-subtab">
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                setSelectedMilestone(milestone);
                                            }}
                                        >
                                            <span>
                                                Click here to view sub deliverables
                                            </span>
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        );
                    })}
                </div>
            </div>
            {selectedMilestone && (
                <RenderSubDeliverables
                    milestone={selectedMilestone}
                    editable={
                        currentMilestone &&
                        currentMilestone.id === selectedMilestone.id
                    }
                    id={id}
                />
            )}
        </div>
    );
};

export default AssignDeliverableContent;
