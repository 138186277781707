import React, { useState } from 'react';
import { Button } from '../../../../Buttons/Button';
import { ExportButton } from '../../../../Buttons/ExportButton';
import SelectInput from '../../../../Inputs/SelectInput';
import { SimpleInput } from '../../../../Inputs/SimpleInput';
import Calendar from '../../../../../../images/calendar.svg';

const options = [
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' },
];

const AddJournal = (props) => {
    const initialValues = {
        title: '',
        journalname: '',
        publisher: '',
        link: '',
        citationnumber: '',
        impactfactor: '',
        author: '',
        year: '',
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (event) => {
        let i = { ...formValues };
        i[event.target.name] = event.target.value;
        setFormValues(i);
    };

    const handleSave = (e) => {
        e.preventDefault();
        let errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            handleSave({ ...formValues });
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.title) {
            errors.title = 'Title name is required*';
        }

        if (!values.journalname) {
            errors.journalname = 'Journal Name is required*';
        }

        if (!values.publisher) {
            errors.publisher = 'Publisher is  required*';
        }

        if (!values.link) {
            errors.link = 'Link is required*';
        }

        if (!values.citationnumber) {
            errors.citationnumber = 'Citation Number is required*';
        }

        if (!values.impactfactor) {
            errors.impactfactor = 'Impact Factor is required*';
        }

        if (!values.author) {
            errors.author = 'Author is required*';
        }

        if (!values.year) {
            errors.year = 'Year is required*';
        }

        return errors;
    };
    return (
        <div className="userprofile-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Add Journal</h5>
                    <span>Update your journal details here</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={props.onClose}
                    src="/images/cross.svg"
                />
            </div>

            <div className="invite-supervisor-content">
                <div className="invite-supervisor">
                    <div className="invite-supervisor-input">
                        <div className="patent-issue-input">
                            <SimpleInput
                                placeholder="Enter title"
                                label="Title*"
                                value={formValues.title}
                                onChange={handleChange}
                                error={formErrors.title}
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter journal name"
                                label="Journal Name*"
                                value={formValues.journalname}
                                onChange={handleChange}
                                error={formErrors.journalname}
                            />
                        </div>
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter publisher"
                                label="Publisher*"
                                value={formValues.publisher}
                                onChange={handleChange}
                                error={formErrors.publisher}
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter link"
                                label="Link*"
                                value={formValues.link}
                                onChange={handleChange}
                                error={formErrors.link}
                            />
                        </div>
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter citation number"
                                label="Citation Number*"
                                value={formValues.citationnumber}
                                onChange={handleChange}
                                error={formErrors.citationnumber}
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter impact factor"
                                label="Impact Factor*"
                                value={formValues.impactfactor}
                                onChange={handleChange}
                                error={formErrors.impactfactor}
                            />
                        </div>
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter h index"
                                label="H Index"
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter authors"
                                label="Authors*"
                                value={formValues.author}
                                onChange={handleChange}
                                error={formErrors.author}
                            />
                        </div>
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter DOI number"
                                label="DOI Number"
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter issue number"
                                label="Issue Number"
                            />
                        </div>
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter ISSN"
                                label="ISSN"
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter publication date"
                                label="Publication Date"
                                lefticon={Calendar}
                            />
                        </div>
                        <div className="supervisor-name">
                            <div className="personal-info-about">Year*</div>
                            <SelectInput
                                placeholder="Select year"
                                label="Year"
                                options={options}
                                value={formValues.year}
                                onChange={handleChange}
                                error={formErrors.year}
                            />
                        </div>
                    </div>
                </div>

                <div className="cb-buttons-userprofile">
                    <ExportButton hover="#F9FAFB" border="1px solid #D0D5DD">
                        Cancel
                    </ExportButton>
                    <Button
                        width="auto"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddJournal;
