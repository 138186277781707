import React from 'react';
import GenericProfileForm from '../../../../../../../components/Form/GenericProfileForm';

const AddProfessional = ({ onClose, id, fields, endpoint, initialValues }) => {
    return (
        <div className="userprofile-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Add Qualification Details</h5>
                    <span>Update your qualification details here</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onClose}
                    src="/images/cross.svg"
                    alt={'Close Modal'}
                />
            </div>

            <GenericProfileForm
                fields={fields}
                id={id}
                initialValues={initialValues}
                onClose={onClose}
                endpoint={endpoint}
            />
        </div>
    );
};

export default AddProfessional;
