import React, { useState } from 'react';
import { Button } from '../../../components/Buttons/Button';
import { SimpleInput } from '../../../components/Inputs/SimpleInput';
import Email from '../../../../images/email.svg';
import PhdCentralLogo from '../../../../images/phd-central-logo.png';
import Api from '../../../../api/api';
import { Link } from 'react-router-dom';

const LoginLeft = (placeholder) => {
    const initialValues = { email: '', password: '' };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [successMsg, setSuccessMsg] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, SetLoading] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (event) => {
        let i = { ...formValues };
        i[event.target.name] = event.target.value;
        setFormValues(i);
    };

    const handleSignin = (e) => {
        e.preventDefault();
        let errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }
        SetLoading(true);
        Api.post(
            '/Authentication/login',
            {
                Email: formValues.email,
                Password: formValues.password,
            },
            false,
        )
            .then((response) => {
                localStorage.setItem('token', response.Token);
                window.location.href = '/dashboard';
            })
            .catch((e) => {
                if (e.Message) {
                    setFormErrors({ error: e.Message });
                } else {
                    setFormErrors({
                        error: 'Something went wrong',
                    });
                }
                SetLoading(false);
            });
    };

    const validate = (values) => {
        setSuccessMsg();
        const errors = {};

        const passwordValue = values.password;

        if (!values.email) {
            errors.email = 'Email is required';
        }

        if (!passwordValue) {
            //setPasswordErr("Password is Empty");
            errors.password = 'Password is required';
        }
        return errors;
    };

    return (
        <>
            <div className="login-page">
                <form>
                    <div className="login-left-content">
                        <div className="login-container">
                            <div className="welcome-heading">
                                <div className="phd-logo-login">
                                    <img
                                        className="phd-logo-login"
                                        src={PhdCentralLogo}
                                        alt={'PhdCentralLogo'}
                                    />
                                </div>
                                <h1>Welcome Back</h1>
                            </div>

                            <div className="login-input">
                                <SimpleInput
                                    className="login-input-field"
                                    type="email"
                                    placeholder="Enter your email"
                                    name="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    error={formErrors.email}
                                    label="Email*"
                                />
                            </div>

                            <div className="login-input">
                                <SimpleInput
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder="Enter your password"
                                    value={formValues.password}
                                    onChange={handleChange}
                                    error={formErrors.password}
                                    label="Password*"
                                    onClick={togglePasswordVisibility}
                                    righticon={
                                        showPassword
                                            ? '../../../images/view.svg'
                                            : '../../../images/view-hide.svg'
                                    }
                                    successMsg={successMsg}
                                />
                            </div>
                            <div className={'error text-danger'}>
                                {formErrors.error}
                            </div>

                            <div className="signin-button">
                                <Button
                                    onClick={handleSignin}
                                    loading={loading}
                                    hover="#5D43A5"
                                    type="submit"
                                >
                                    Login
                                </Button>
                            </div>
                            <div className="forgot-reset-link">
                                <Link to={'/forgot'}>Forgot password ?</Link>
                            </div>
                        </div>
                        <div className="footer">
                            <span>© Chitkara University 2022</span>
                            <a href="mailto:help@chitkara.edu.in">
                                <img
                                    className="email"
                                    src={Email}
                                    alt={'Email'}
                                />
                                help@chitkara.edu.in
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default LoginLeft;
