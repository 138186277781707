import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../Buttons/Button';
import { ExportButton } from '../../../../../Buttons/ExportButton';
import { FixedPlaceholderInput } from '../../../../../Inputs/FixedPlaceholderInput';
import SelectInput from '../../../../../Inputs/SelectInput';
import Api from '../../../../../../../api/api';
import { TextAreaInput } from '../../../../../Inputs/TextAreaInput';

const Collection = ({ payable, onClose, id }) => {
    const [amount, setAmount] = useState('');
    const [notes, setNotes] = useState('');
    const [mode, setMode] = useState('1');
    const [modes, setModes] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Api.get('/Common/CommonEnums/getEnums').then((response) => {
            setModes(
                response.PaymentType.map((m) => {
                    return { label: m, value: m };
                }),
            );
        });
    }, []);

    const submit = () => {

        setErrors({});
        setLoading(true);
        if(mode === 'Online'){
            Api.post('/FeeStructure/FeePayment/CreateRequestPacket',{PayableAmount: amount,UserId:id})
                .then(response=>{
                    window.location.href = response.PaymentUrl;
                })
            return;
        }
        Api.post('/FeeStructure/Transaction/PostEntity', {
            UserId: id,
            AmountPaid: amount,
            PaymentType: mode,
            TransactionId: null,
            Date: '2022-10-07',
            Status: 1,
            CreatedBy: null,
            TransactionDetails: JSON.stringify({ notes }),
        })
            .then(() => {
                onClose();
            })
            .catch((e) => {
                setErrors(e.Fields);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="fee-policy-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Collection</h5>
                    <span>Enter the amount you have received</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onClose}
                    src="/images/cross.svg"
                    alt="close"
                />
            </div>

            <div className="paybal-amount">
                <h6>Paybal Amount</h6>
                <span>₹{payable}</span>
            </div>

            <div className="fee-policy-content">
                <div className="fee-policy-input">
                    <div className="fee-mode-name">
                        <div className="payment-label">Select Mode</div>
                        <SelectInput
                            onChange={(selected) => {
                                setMode(selected.value);
                            }}
                            placeholder="Select mode"
                            options={modes}
                            value={mode}
                        />
                    </div>
                    <div className="payment-mode-name">
                        <div className="payment-label">Payment</div>
                        <FixedPlaceholderInput
                            className="hello"
                            placeholder="Enter amount"
                            fixedtext="₹"
                            value={amount}
                            error={errors.AmountPaid}
                            onChange={(e) => {
                                setAmount(e.target.value);
                            }}
                            width="45px"
                            name="website"
                            label=""
                        />
                    </div>
                    <div className="payment-mode-name">
                        <div className="payment-label">Notes</div>
                        <TextAreaInput
                            className="hello"
                            placeholder="Enter notes"
                            value={notes}
                            onChange={(e) => {
                                setNotes(e.target.value);
                            }}
                            label=""
                        />
                    </div>
                </div>

                <div className="cb-buttons-fee-policy">
                    <ExportButton
                        width="170px"
                        height="40px"
                        hover="#F9FAFB"
                        onClick={onClose}
                        border="1px solid #D0D5DD"
                    >
                        Cancel
                    </ExportButton>
                    <Button
                        loading={loading}
                        onClick={submit}
                        width="170px"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Collection;
