import React from 'react';
import { Button } from '../../../../../../../components/Buttons/Button';
import { ExportButton } from '../../../../../../../components/Buttons/ExportButton';
import Heading from '../../../../../../../components/Heading/Heading';
import { FixedPlaceholderInput } from '../../../../../../../components/Inputs/FixedPlaceholderInput';

const SocialSiteContent = () => {
    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title="Social Sites"
                    description="Upload your social site details here."
                />
            </div>

            <div className="personal-info-data">
                <div className="personal-name">
                    <FixedPlaceholderInput fixedtext="ORCID ID" width="130px" />
                </div>
            </div>

            <div className="personal-info-data">
                <div className="personal-name">
                    <FixedPlaceholderInput fixedtext="SCOPUS ID" />
                </div>
            </div>

            <div className="personal-info-data">
                <div className="personal-name">
                    <FixedPlaceholderInput
                        fixedtext="PUBLONS ID"
                        width="180px"
                    />
                </div>
            </div>

            <div className="personal-info-data">
                <div className="personal-name">
                    <FixedPlaceholderInput
                        fixedtext="Research Gate"
                        width="200px"
                    />
                </div>
            </div>

            <div className="personal-info-data">
                <div className="personal-name">
                    <FixedPlaceholderInput fixedtext="Academia" width="140px" />
                </div>
            </div>

            <div className="personal-info-data">
                <div className="personal-name">
                    <FixedPlaceholderInput fixedtext="LinkedIn" width="120px" />
                </div>
            </div>

            <div className="personal-info-data">
                <div className="personal-name">
                    <FixedPlaceholderInput
                        fixedtext="Google Scholars"
                        width="230px"
                    />
                </div>
            </div>

            <div className="cb-buttons-userprofile">
                <ExportButton hover="#F9FAFB" border="none">
                    Skip
                </ExportButton>
                <Button
                    width="auto"
                    height="40px"
                    padding="0px 20px !important"
                    hover="#5D43A5"
                >
                    Save Changes
                </Button>
            </div>
        </div>
    );
};

export default SocialSiteContent;
