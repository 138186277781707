import React from 'react';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Sidebar from '../../../components/Sidebar/Sidebar';
import GeneralView from './Subcomponents/GeneralView';
import { useParams } from 'react-router-dom';
import useDetailApi from '../../../../hooks/useDetailApi';
import Backarrow from '../../../../images/back-arrow.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';

const ViewCampus = () => {
    const { id } = useParams();
    const { loading, item } = useDetailApi('Infrastructure/Campus', id);

    const fields = [
        {
            type: 'text',
            field: 'UniversityName',
            label: 'University name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'DisplayName',
            label: 'Campus name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'LegalName',
            label: 'Legal Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Campus Code',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Website',
            label: 'Website',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Email',
            label: 'Email',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PhoneNumber',
            label: 'Phone Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'GSTNo',
            label: 'GST No',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'textarea',
            field: 'Vision',
            label: 'Vision',
            description: 'This will be displayed on your profile.',
            required: false,
        },
        {
            type: 'textarea',
            field: 'Mission',
            label: 'Mission',
            description: 'This will be displayed on your profile.',
            required: false,
        },
        {
            type: 'textarea',
            field: 'PermanentAddress',
            label: 'Permanent Address',
            description: 'This will be displayed on your profile.',
            required: false,
        },
        {
            type: 'textarea',
            field: 'CurrentAddress',
            label: 'Current Address',
            description: 'This will be displayed on your profile.',
            required: false,
        },
    ];

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <Sidebar />
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    breadcrumb="Campuses"
                    breadcrumbs="View Campus"
                    innerpath="/dashboard"
                />
                <Heading
                    title="Campus Details"
                    description="View all details of the campus below"
                    linktext="Back to Campuses"
                    icon={Backarrow}
                    href="/campus"
                    width="auto"
                    height="44px"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    padding="0 20px 0 20px !important"
                    className="view-back-button"
                />
                {loading ? (
                    <div> Loading... </div>
                ) : (
                    <>
                        <GeneralView campus={item} fields={fields} />
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewCampus;
