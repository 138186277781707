import React, {useRef, useState} from 'react';
import GenericForm from '../../../../../../components/Form/GenericForm';
import qs from 'qs';

const AddDRCFacultyContent = () => {
    let filters = qs.parse(window.location.search, {ignoreQueryPrefix: true});

    const [hideFacultyType, setHideFacultyType] = useState(true);

    const initialValues = {
        CampusId: filters.CampusId || '',
        ClusterId: filters.ClusterId || '',
        ResearchCenterId: filters.ResearchCenterId || '',
        Honorifics: '',
        FirstName: '',
        Gender: '',
        Email: '',
        PhoneNumber: '',
        EmployeeId: '',
        Designation: '',
        ProfilePhoto: '',
        MiddleName: null,
        LastName: '',
        UserType: '',
        FacultyType: '',
        IsFaculty: '',
        DisciplineId: []
    };

    const formRef = useRef(null);

    const fields = [
        {
            type: 'file',
            fileType: 'image/*',
            field: 'ProfilePhoto',
            required: false,
            fullWidth: true,
        },
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'The employee will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ClusterId: '',
                    ResearchCenterId: '',
                    MilestoneId: '',
                });
            },
        },
        {
            type: 'select',
            field: 'ClusterId',
            label: 'Select Registry',
            description: 'The employee will be added to the selected Registry',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ResearchCenterId: '',
                    BatchId: '',
                    ProgramId: '',
                    DisciplineId: '',
                });
            },
        },
        {
            type: 'select',
            field: 'ResearchCenterId',
            label: 'Select Disciplines',
            description:
                'The employee will be added to the selected discipline',
            estric: '*',
            className: 'select-multi',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                multi: true,
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({
                    BatchId: '',
                    ProgramId: '',
                    DisciplineId: '',
                });
            },
        },
        {
            type: 'text',
            field: 'EmployeeId',
            label: 'Employee Id',
            description: ' Unique identifier for the employee',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Designation',
            label: 'Designation',
            description: 'The position of the employee',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Honorifics',
            label: 'Title',
            description: 'Title to be added before the name of the employee',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Honorifics',
            },
        },

        {
            type: 'text',
            field: 'FirstName',
            label: 'First Name',
            description: 'First name of the employee',
            estric: '*',
        },
        {
            type: 'text',
            field: 'MiddleName',
            label: 'Middle Name',
            description: 'Last name of the employee',
            required: false
        },
        {
            type: 'text',
            field: 'LastName',
            label: 'Last Name',
            description: 'Last name of the employee',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Gender',
            label: 'Gender',
            description: 'Gender of the employee',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Gender',
            },
        },
        {
            type: 'text',
            field: 'Email',
            label: 'Email',
            description: 'Email address of the employee',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PhoneNumber',
            label: 'Phone Number',
            description: 'The phone number of the employee',
            estric: '*',
        },
        {
            type: 'select',
            field: 'IsFaculty',
            label: 'Is Faculty',
            description: 'Is this user a faculty member?',
            estric: '*',
            options: {
                type: 'static',
                options: [{
                    value: '1', label: 'Yes'
                }, {
                    value: '0', label: 'No'
                }]
            },
            onChange: (value) => {
                setHideFacultyType(value === '0');
            },
        },

    ];

    if (!hideFacultyType) {
        fields.push({
            type: 'select',
            field: 'FacultyType',
            label: 'Faculty Type',
            description: 'The type of faculty member',
            estric: '*',
            options: {
                type: 'static',
                options: [{
                    value: 'Internal', label: 'Internal'
                }, {
                    value: 'External', label: 'External'
                }]
            },
        });
    }

    return (
        <GenericForm
            fields={fields}
            initialValues={initialValues}
            endpoint={'Users/Staff'}
            redirectTo={'/staff'}
            initialValuesUpdated={initialValues => {
                setHideFacultyType(initialValues.IsFaculty === '0');
            }}
        />
    );
};

export default AddDRCFacultyContent;
