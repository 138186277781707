import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Completeprofile from '../../../images/complete-profile.svg';
import Activeprofile from '../../../images/active-profile.svg';
import Inactiveprofile from '../../../images/inactive-profile.svg';
import MediaQuery from 'react-responsive';
import UserProfileSelectDropdown from '../User Profile Mobile Design/UserProfileSelectDropdown';

const Complete = () => {
    return (
        <img
            className="progress-complete"
            src={Completeprofile}
            alt={'Inactiveprofile'}
        />
    );
};
const InComplete = () => {
    return (
        <img
            className="progress-complete"
            src={Inactiveprofile}
            alt={'Inactiveprofile'}
        />
    );
};
const Active = () => {
    return (
        <img
            className="progress-complete"
            src={Activeprofile}
            alt={'Inactiveprofile'}
        />
    );
};

const StatusIcon = ({ section }) => {
    if (window.location.href.endsWith(`/${section}`)) {
        return <Active />;
    }
    return <InComplete />;
};

const Progressbar = () => {
    const [condition] = useState(false);
    const { scholarId } = useParams();

    return (
        <div className="user-profile-select">
            <MediaQuery maxWidth={1023}>
                <UserProfileSelectDropdown />
            </MediaQuery>
            <div className="progress-bar">
                <div className="progress-bar-content">
                    <ul>
                        <li>
                            <Link to={`/scholar/${scholarId}/personal`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'personal'} />
                                    <span>Personal Information</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/scholar/${scholarId}/address`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'address'} />
                                    <span>Address</span>
                                </div>
                            </Link>
                        </li>


                        <li>
                            <Link to={`/scholar/${scholarId}/professional`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'professional'} />
                                    <span>Experience</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/scholar/${scholarId}/qualifications`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'qualifications'} />
                                    <span>Qualification</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/scholar/${scholarId}/projects`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'projects'} />
                                    <span>Projects</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/scholar/${scholarId}/patents`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'patents'} />
                                    <span>Patent Details</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/scholar/${scholarId}/awards`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'awards'} />
                                    <span>Award Details</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/scholar/${scholarId}/scholars`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'scholars'} />
                                    <span>Pior Scholar Information</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/scholar/${scholarId}/social`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'social'} />
                                    <span>Social Sites</span>
                                </div>
                            </Link>
                        </li>


                        <li>
                            <Link to={`/scholar/${scholarId}/publications`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'publications'} />
                                    <span>Publications Details</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/scholar/${scholarId}/documents`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'documents'} />
                                    <span>Upload Documents</span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Progressbar;
