import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import {Button} from '../Buttons/Button';
import {ExportButton} from '../Buttons/ExportButton';
import SelectInput from '../Inputs/SelectInput';
import Api from '../../../api/api';
import {useParams} from 'react-router-dom';
import useDetailApi from '../../../hooks/useDetailApi';
import * as PropTypes from 'prop-types';
import {SimpleInput} from '../Inputs/SimpleInput';
import Switch from '../Switch/Switch';
import {TextAreaInput} from '../Inputs/TextAreaInput';
import _ from 'lodash';
import {FixedPlaceholderInput} from '../Inputs/FixedPlaceholderInput';
import {FixedInput} from '../Inputs/FixedInput';
import {FileInput} from '../Inputs/FileInput';
import {HTMLInput} from '../Inputs/HTMLInput';
import {FilesInput} from '../Inputs/FilesInput';

export function RenderFormControl({
                                      type,
                                      formErrors,
                                      handleChange,
                                      field,
                                      onLabel,
                                      offLabel,
                                      formValues,
                                      options,
                                      placeholder,
                                      fixedtext,
                                      className,
                                      fileType,
                                  }) {
    const [_options, setOptions] = useState([]);

    useEffect(() => {
        if (!options) {
            return;
        }
        if (options.type === 'static') {
            let opts = [];
            if (options.all) {
                opts.push({
                    value: options.showAllValue,
                    label: options.showAllLabel,
                });
            }
            setOptions([...opts,...options.options]);
        } else if (options.type === 'enum') {
            Api.get(`/Common/CommonEnums/getEnums`).then((response) => {
                let opts = response[options.value].map((item) => {
                    return {
                        value: item,
                        label: item,
                    };
                });
                setOptions(opts);
            });
        } else {
            Api.get(`/${options.endpoint}/getEntities`, {
                ...(options.endpointQueryParams || {}),
                PageNo: 1,
                PerPage: 1000,
            }).then((response) => {
                let rows = response.Data ? response.Data : response;
                let opts = [];
                if (options.all) {
                    opts.push({
                        value: options.showAllValue,
                        label: options.showAllLabel,
                    });
                }
                if (options.optionsFilterCallback) {
                    rows = rows.filter((row) => {
                        return options.optionsFilterCallback(row, formValues);
                    });
                }
                rows.forEach((item) => {
                    opts.push({
                        value: _.get(item, options.valueKey),
                        label: _.get(item, options.labelKey),
                    });
                });
                setOptions(opts);
            });
        }
    }, [formValues, options]);

    switch (type) {
        case 'select':
            return (
                <SelectInput
                    multi={options.multi}
                    className={className}
                    error={formErrors[field]}
                    onChange={(selected) => {
                        let value = selected.value;
                        if (options.multi) {
                            value = selected.map(({value}) => value);
                        }
                        handleChange({
                            target: {
                                name: field,
                                value,
                            },
                        });
                    }}
                    value={formValues[field]}
                    options={_options}
                    placeholder={placeholder}
                />
            );
        case 'text':
            return (
                <SimpleInput
                    placeholder={placeholder}
                    name={field}
                    label=""
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'fixedinput':
            return (
                <FixedInput
                    fixedtext={fixedtext}
                    label=""
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'fixedplaceholderinput':
            return (
                <FixedPlaceholderInput
                    placeholder={placeholder}
                    name={field}
                    label=""
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'date':
            return (
                <SimpleInput
                    placeholder={placeholder}
                    name={field}
                    type="date"
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'files':
            return (
                <FilesInput
                    placeholder={placeholder}
                    name={field}
                    value={formValues[field]}
                    type="file"
                    multiple
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'password':
            return (
                <SimpleInput
                    placeholder={placeholder}
                    name={field}
                    type="password"
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'switch':
            return (
                <Switch
                    name={field}
                    onLabel={onLabel}
                    offLabel={offLabel}
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'textarea':
            return (
                <TextAreaInput
                    placeholder={placeholder}
                    name={field}
                    type="date"
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'html':
            return (
                <HTMLInput
                    placeholder={placeholder}
                    name={field}
                    type="date"
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'file':
            return (
                <FileInput
                    placeholder={placeholder}
                    name={field}
                    fileType={fileType}
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        default:
            return '';
    }
}

RenderFormControl.propTypes = {
    formErrors: PropTypes.shape({}),
    onChange: PropTypes.func,
    formValues: PropTypes.shape({
        CreatedBy: PropTypes.number,
        CampusId: PropTypes.number,
        Code: PropTypes.string,
        Name: PropTypes.string,
    }),
};
const GenericForm = (
    {
        fields,
        initialValues,
        endpoint,
        redirectTo,
        twoColumns = false,
        RenderCustomFields,
        initialValuesUpdated
    },
    ref,
) => {
    const [initialData, setInitialData] = useState(null);
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        updateData: (d) => {
            setFormValues((v) => {
                console.log({v});
                return {...v, ...d};
            });
        },
    }));

    useEffect(() => {
        console.log({formValues});
    }, [formValues]);

    const {id} = useParams();

    const {loading: initialising, item} = useDetailApi(endpoint, id);

    useEffect(() => {
        if (item) {
            let d = {};
            Object.keys(item).map((k) => {
                let value = item[k];
                fields.forEach((f) => {
                    if (f.serverField === k && f.initialDataMapper) {
                        value = f.initialDataMapper(item);
                        d[f.field] = value;
                    }
                });
                d[k] = value;
            });
            setFormValues({...d});
            setInitialData(d);
            if (initialValuesUpdated) {
                initialValuesUpdated(d)
            }
        }
    }, [item]);

    const handleChange = (event) => {
        console.log({event});
        let i = {...formValues};
        i[event.target.name] = event.target.value;

        setFormValues(i);

        let f = fields.find((field) => field.field === event.target.name);
        if (f && f.onChange) {
            f.onChange(event.target.value);
        }
    };

    const submit = () => {
        setLoading(true);
        Api.createObject(endpoint, formValues, id)
            .then((response) => {
                window.location.href = redirectTo;
            })
            .catch((e) => {
                if (e && e.Fields) {
                    setFormErrors({...e.Fields});
                } else if (e && e.Message) {
                    alert(e.Message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleNext = (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            submit();
        }
    };

    const validate = (values) => {
        const errors = {};
        fields.forEach((field) => {
            if (!field.field) {
                //This is just a filler
                return;
            }
            if (
                (!values[field.field] || values[field.field] === 'undefined') &&
                field.required !== false
            ) {
                errors[field.field] = `${field.label}  is required`;
            }
        });
        return errors;
    };

    if (twoColumns) {
        const splitedFields = fields.reduce(function (
                accumulator,
                currentValue,
                currentIndex,
                array,
            ) {
                if (currentIndex % 2 === 0)
                    accumulator.push(array.slice(currentIndex, currentIndex + 2));
                return accumulator;
            },
            []);
        let previousFieldFullWidth = false;
        return (
            <>
                <div className="invite-supervisor-content">
                    <div className="invite-supervisor">
                        {splitedFields.map((fields) => {
                            return (
                                <>
                                    <div className="invite-supervisor-inputs">
                                        {fields.map(
                                            ({
                                                 label,
                                                 description,
                                                 field,
                                                 type,
                                                 options,
                                                 estric,
                                                 fullWidth,
                                                 fileType,
                                             }) => {
                                                if (
                                                    !field &&
                                                    previousFieldFullWidth
                                                ) {
                                                    return <></>;
                                                }
                                                previousFieldFullWidth =
                                                    fullWidth;
                                                return (
                                                    <>
                                                        <div className="supervisor-name">
                                                            {label && (
                                                                <label>
                                                                    {label}
                                                                    {estric}
                                                                </label>
                                                            )}
                                                            <RenderFormControl
                                                                options={
                                                                    options
                                                                }
                                                                placeholder={
                                                                    label
                                                                }
                                                                type={type}
                                                                field={field}
                                                                formValues={
                                                                    formValues
                                                                }
                                                                formErrors={
                                                                    formErrors
                                                                }
                                                                handleChange={
                                                                    handleChange
                                                                }
                                                                fileType={
                                                                    fileType
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                );
                                            },
                                        )}
                                    </div>
                                    {fields[0]['field'] === 'ProgramId' &&
                                        fields[1]['field'] ===
                                        'DisciplineId' && (
                                            <>
                                                <div className="bottom-line-scholar"></div>
                                            </>
                                        )}
                                    {fields[0]['field'] === 'Nationality' && (
                                        <>
                                            <div className="bottom-line-scholar"></div>
                                        </>
                                    )}
                                </>
                            );
                        })}
                    </div>
                </div>
                <div className="cb-buttons">
                    <ExportButton
                        hover="#f9fafb"
                        onClick={() => {
                            window.history.back();
                        }}
                    >
                        Cancel
                    </ExportButton>
                    <Button
                        disabled={loading}
                        width="auto"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                        onClick={handleNext}
                    >
                        {loading ? 'Please wait..' : 'Save'}
                    </Button>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="tabs-border-bottom">
                {fields.map(
                    ({
                         label,
                         description,
                         field,
                         type,
                         options,
                         estric,
                         className,
                         fullWidth,
                         fileType,
                     }) => {
                        return (
                            <div key={field}>
                                <div className="university-input">
                                    {fullWidth !== true && (
                                        <div className="university-input-heading">
                                            <h4
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {label}
                                                {estric}
                                            </h4>
                                            <div className="span-text">
                                                <span>{description}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="university">
                                        <div
                                            className={`university-name-input ${
                                                fullWidth ? 'fullWidth' : ''
                                            }`}
                                        >
                                            <RenderFormControl
                                                className={className}
                                                fileType={fileType}
                                                options={options}
                                                placeholder={label}
                                                type={type}
                                                field={field}
                                                formValues={formValues}
                                                formErrors={formErrors}
                                                handleChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-line"></div>
                            </div>
                        );
                    },
                )}
                {RenderCustomFields !== undefined && (
                    <RenderCustomFields
                        handleChange={handleChange}
                        initialData={initialData}
                    />
                )}
            </div>

            <div className="cb-buttons">
                <ExportButton
                    hover="#f9fafb"
                    onClick={() => {
                        window.history.back();
                    }}
                >
                    Cancel
                </ExportButton>
                <Button
                    disabled={loading}
                    width="auto"
                    height="40px"
                    padding="0px 20px 0px 20px !important"
                    hover="#5D43A5"
                    onClick={handleNext}
                >
                    {loading ? 'Please wait..' : 'Save'}
                </Button>
            </div>
        </>
    );
};

export default forwardRef(GenericForm);
