import React from 'react';
import Header from '../../../../../../../components/Header/Header';
import Heading from '../../../../../../../components/Heading/Heading';
import Progressbar from '../../../../../../../components/Progress Bar/Progressbar';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import MediaQuery from 'react-responsive';
import { useParams } from 'react-router-dom';
import GenericContent from '../Generic/GenericContent';

const Project = () => {
    const { scholarId } = useParams();

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    title="Scholars"
                    breadcrumbs="My Profile"
                    href="/scholar"
                />
                <Heading title="My Profile" description="" />
                <div className="user-profile-section">
                    <Progressbar />
                    <GenericContent
                        id={scholarId}
                        column={[
                            {
                                heading: 'Title',
                                value: '',
                            },
                            {
                                heading: 'Duration',
                                value: '',
                            },
                            {
                                heading: 'Funding Agency',
                                value: '',
                            },
                            {
                                heading: 'Start Date',
                                value: '',
                            },
                        ]}
                        endpoint={'Patent'}
                        fields={[
                            {
                                type: 'text',
                                field: 'Title',
                                label: 'Title',
                                estric: '*',
                            },
                            {
                                type: 'text',
                                field: 'PatentNumber',
                                label: 'Patent Number',
                                description:
                                    'This will be displayed on your profile.',
                                estric: '*',
                            },
                            {
                                type: 'text',
                                field: 'PatentURL',
                                label: 'Patent URL',
                                description:
                                    'This will be displayed on your profile.',
                                estric: '*',
                                required: false,
                            },
                            {
                                type: 'date',
                                field: 'PatentIssueDate',
                                label: 'Patent Issue Date',
                                description:
                                    'This will be displayed on your profile.',
                                estric: '*',
                                required: false,
                            },
                            {
                                type: 'date',
                                field: 'PatentFileDate',
                                label: 'Patent File Date',
                                description:
                                    'This will be displayed on your profile.',
                                estric: '*',
                                required: false,
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default Project;
