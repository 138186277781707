import React, { useEffect, useState } from 'react';
import { Button } from '../Buttons/Button';
import { ExportButton } from '../Buttons/ExportButton';
import SelectInput from '../Inputs/SelectInput';
import Api from '../../../api/api';
import * as PropTypes from 'prop-types';
import { SimpleInput } from '../Inputs/SimpleInput';
import Switch from '../Switch/Switch';
import { TextAreaInput } from '../Inputs/TextAreaInput';
import _ from 'lodash';
import { FixedPlaceholderInput } from '../Inputs/FixedPlaceholderInput';
import { FixedInput } from '../Inputs/FixedInput';
import useProfileDetailApi from '../../../hooks/useProfileDetailApi';

function RenderFormControl({
    type,
    formErrors,
    handleChange,
    field,
    onLabel,
    offLabel,
    formValues,
    options,
    placeholder,
    fixedtext,
}) {
    const [_options, setOptions] = useState([]);

    useEffect(() => {
        if (!options) {
            return;
        }
        if (options.type === 'static') {
            setOptions([...options.options]);
        } else {
            Api.get(`/${options.endpoint}/getEntities`).then((rows) => {
                setOptions(
                    rows.map((item) => {
                        return {
                            value: _.get(item, options.valueKey),
                            label: _.get(item, options.labelKey),
                        };
                    }),
                );
            });
        }
    }, [options]);

    switch (type) {
        case 'select':
            return (
                <SelectInput
                    error={formErrors[field]}
                    onChange={({ value }) => {
                        handleChange({
                            target: {
                                name: field,
                                value,
                            },
                        });
                    }}
                    value={formValues[field]}
                    options={_options}
                    placeholder={placeholder}
                />
            );
        case 'text':
            return (
                <SimpleInput
                    placeholder={placeholder}
                    name={field}
                    label=""
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'fixedinput':
            return (
                <FixedInput
                    fixedtext={fixedtext}
                    label=""
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'fixedplaceholderinput':
            return (
                <FixedPlaceholderInput
                    placeholder={placeholder}
                    name={field}
                    label=""
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'date':
            return (
                <SimpleInput
                    placeholder={placeholder}
                    name={field}
                    type="date"
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'password':
            return (
                <SimpleInput
                    placeholder={placeholder}
                    name={field}
                    type="password"
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'switch':
            return (
                <Switch
                    name={field}
                    onLabel={onLabel}
                    offLabel={offLabel}
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'textarea':
            return (
                <TextAreaInput
                    placeholder={placeholder}
                    name={field}
                    type="date"
                    value={formValues[field]}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        default:
            return <>Component Not Implemented</>;
    }
}

RenderFormControl.propTypes = {
    formErrors: PropTypes.shape({}),
    onChange: PropTypes.func,
    formValues: PropTypes.shape({
        CreatedBy: PropTypes.number,
        CampusId: PropTypes.number,
        Code: PropTypes.string,
        Name: PropTypes.string,
    }),
};
const GenericProfileForm = ({
    fields,
    initialValues,
    endpoint,
    onClose,
    id,
}) => {
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const { loading: initialising, item } = useProfileDetailApi(endpoint, id);

    useEffect(() => {
        if (item) {
            setFormValues({ ...item, UserProfile: initialValues.UserProfile });
        }
    }, [item]);

    const handleChange = (event) => {
        let i = { ...formValues };
        i[event.target.name] = event.target.value;
        setFormValues(i);
    };

    const submit = () => {
        setLoading(true);
        Api.createProfileObject(`${endpoint}`, formValues, id)
            .then((response) => {
                onClose();
            })
            .catch((e) => {
                if (e && e.Fields) {
                    setFormErrors({ ...e.Fields });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleNext = (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            submit();
        }
    };

    const validate = (values) => {
        const errors = {};
        fields.forEach((field) => {
            if (!values[field.field] && field.required !== false) {
                errors[field.field] = `${field.label}  is required*`;
            }
        });
        return errors;
    };

    return (
        <>
            <div className="profile-form-container">
                {fields.map(
                    ({ label, description, field, type, options, estric }) => {
                        return (
                            <div key={field}>
                                <div className="university-input-heading">
                                    <h4>
                                        {label}
                                        {estric}
                                    </h4>
                                </div>
                                <RenderFormControl
                                    options={options}
                                    placeholder={label}
                                    type={type}
                                    field={field}
                                    formValues={formValues}
                                    formErrors={formErrors}
                                    handleChange={handleChange}
                                />
                            </div>
                        );
                    },
                )}
            </div>

            <div className="cb-buttons">
                <ExportButton hover="#f9fafb" onClick={onClose}>
                    Cancel
                </ExportButton>
                <Button
                    disabled={loading}
                    width="auto"
                    height="40px"
                    padding="0px 20px !important"
                    hover="#5D43A5"
                    onClick={handleNext}
                >
                    {loading ? 'Please wait..' : 'Save'}
                </Button>
            </div>
        </>
    );
};

export default GenericProfileForm;
