import {useEffect, useState} from 'react';

const useFilters = (filters, filterValues, showAll = true) => {
    const [filterFields, setFilterFields] = useState([]);

    function getFilterConfiguration(filter, filterValues) {
        switch (filter) {
            case 'campus':
                return {
                    field: 'CampusId',
                    placeholder: 'Select Campus',
                    options: {
                        type: 'dynamic',
                        endpoint: 'Infrastructure/Campus',
                        paged: true,
                        valueKey: 'Id',
                        labelKey: 'DisplayName',
                        all: showAll,
                        showAllLabel: 'All Campuses',
                        showAllValue: null,
                    },
                };
            case 'cluster':
                return {
                    field: 'ClusterId',
                    placeholder: 'Select Registry',
                    options: {
                        type: 'dynamic',
                        endpoint: `Infrastructure/Cluster`,
                        endpointQueryParams: filterValues.CampusId
                            ? {CampusId: filterValues.CampusId}
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return cluster.CampusId === formValue.CampusId;
                        },
                        valueKey: 'Id',
                        labelKey: 'Name',
                        all: showAll,
                        showAllLabel: 'All Registries',
                        showAllValue: null,
                    },
                };
            case 'researchCenter':
                return {
                    field: 'ResearchCenterId',
                    placeholder: 'Select Discipline',
                    options: {
                        type: 'dynamic',
                        endpoint: `Infrastructure/ResearchCenter`,
                        endpointQueryParams: filterValues.ClusterId
                            ? {ClusterId: filterValues.ClusterId}
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return cluster.ClusterId === formValue.ClusterId;
                        },
                        valueKey: 'Id',
                        labelKey: 'Name',
                        all: showAll,
                        showAllLabel: 'All Disciplines',
                        showAllValue: null,
                    },
                };
            case 'academicYear':
                return {
                    field: 'AcademicYearId',
                    placeholder: 'Select Academic Year',
                    options: {
                        type: 'dynamic',
                        endpoint: `AcademicStructure/AcademicYear`,
                        endpointQueryParams: filterValues.ResearchCenterId
                            ? {
                                CampusId: filterValues.CampusId,
                            }
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return cluster.CampusId === formValue.CampusId;
                        },
                        valueKey: 'Id',
                        labelKey: 'Name',
                        all: showAll,
                        showAllLabel: 'All Academic Years',
                        showAllValue: null,
                    },
                };
            case 'program':
                return {
                    field: 'ProgramId',
                    placeholder: 'Select Program',
                    options: {
                        type: 'dynamic',
                        endpoint: `AcademicStructure/Program`,
                        endpointQueryParams: filterValues.ResearchCenterId
                            ? {
                                ResearchCenterId:
                                filterValues.ResearchCenterId,
                            }
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return (
                                cluster.ResearchCenterId ===
                                formValue.ResearchCenterId
                            );
                        },
                        valueKey: 'Id',
                        labelKey: 'Name',
                        all: showAll,
                        showAllLabel: 'All Programs',
                        showAllValue: null,
                    },
                };
            case 'batch':
                return {
                    field: 'BatchId',
                    placeholder: 'Select Batch',
                    options: {
                        type: 'dynamic',
                        endpoint: `AcademicStructure/Batch`,
                        endpointQueryParams: filterValues.ResearchCenterId
                            ? {
                                ResearchCenterId:
                                filterValues.ResearchCenterId,
                            }
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return (
                                cluster.ResearchCenterId ===
                                formValue.ResearchCenterId
                            );
                        },
                        valueKey: 'Id',
                        labelKey: 'Name',
                        all: showAll,
                        showAllLabel: 'All Batches',
                        showAllValue: null,
                    },
                };
            case 'discipline':
                return {
                    field: 'DisciplineId',
                    placeholder: 'Select Specialization',
                    options: {
                        type: 'dynamic',
                        endpoint: `AcademicStructure/Discipline`,
                        endpointQueryParams: filterValues.ResearchCenterId
                            ? {
                                ProgramId: filterValues.ProgramId,
                            }
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return cluster.ProgramId === formValue.ProgramId;
                        },
                        valueKey: 'Id',
                        labelKey: 'Name',
                        all: showAll,
                        showAllLabel: 'All Specializations',
                        showAllValue: null,
                    },
                };
            case 'milestone':
                return {
                    field: 'MilestoneId',
                    placeholder: 'Select Deliverable',
                    options: {
                        type: 'dynamic',
                        endpoint: `AcademicStructure/Milestone`,
                        endpointQueryParams: filterValues.ResearchCenterId
                            ? {
                                ResearchCenterId:
                                filterValues.ResearchCenterId,
                            }
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return (
                                cluster.ResearchCenterId ===
                                formValue.ResearchCenterId
                            );
                        },
                        valueKey: 'id',
                        labelKey: 'Title',
                        all: showAll,
                        showAllLabel: 'All Milestones',
                        showAllValue: null,
                    },
                };
            case 'IsFaculty':
                return {
                    field: 'IsFaculty',
                    placeholder: 'Is Faculty?',
                    options: {
                        type: 'static',
                        options: [
                            {'value': '1', label: 'Yes'},
                            {'value': '0', label: 'No'},
                        ],
                        all: showAll,
                        showAllLabel: 'Both',
                        showAllValue: null,
                    },
                };
            case 'FacultyType':
                return {
                    field: 'FacultyType',
                    placeholder: 'Faculty Type',
                    options: {
                        type: 'static',
                        options: [
                            {'value': 'Internal', label: 'Internal'},
                            {'value': 'External', label: 'External'},
                        ],
                        all: showAll,
                        showAllLabel: 'Both',
                        showAllValue: null,
                    },
                };
            default:
                return null;
        }
    }

    useEffect(() => {
        let ff = [];
        filters.forEach((filter) => {
            let value = getFilterConfiguration(filter, filterValues);
            if (value) {
                ff.push(value);
            }
        });
        setFilterFields(ff);
    }, [filters, filterValues]);

    return {filterFields};
};
export default useFilters;
