import React from 'react';
import { useNavigate } from 'react-router-dom';
import useListApi from '../../../../../../../../hooks/useListApi';
import Heading from '../../../../../../../components/Heading/Heading';
import SelectInput from '../../../../../../../components/Inputs/SelectInput';
import Table from '../../../../../../../components/Table/Table';
import UploadDragDrop from '../../../../../../../components/Upload File/UploadDragDrop';

const options = [
    { value: 'aadhar card', label: 'Aadhar Card' },
    { value: 'pan card', label: 'Pan Card' },
];

const UploadDocumentsContent = () => {
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Campus');

    const navigate = useNavigate();

    const column = [
        {
            heading: 'File Name',
            value: '',
        },
        {
            heading: 'Document Type',
            value: '',
        },
        {
            heading: 'Date Uploaded',
            value: '',
        },
        {
            heading: 'Actions',
            value: '',
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'cluster') {
            return (
                <td>
                    <a href={`/registry?CampusId=${row.CampusId}`}>Registry</a>
                </td>
            );
        }
        return rendered;
    };

    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title="Upload Documents"
                    description="Upload your documents here."
                />
            </div>

            <div className="personal-info">
                <div className="user-personal-info-data">
                    <div className="personal-name-input">
                        <div className="personal-info-about">
                            Type of Document
                        </div>
                        <SelectInput
                            options={options}
                            placeholder="Select type of document"
                        />
                    </div>
                </div>

                <div>
                    <UploadDragDrop />
                </div>
            </div>

            <Table
                shouldView={false}
                checkboxshouldView={false}
                resource={'Campus'}
                refresh={refresh}
                data={rows}
                totalRecords={totalRecords}
                perPage={recordsPerPage}
                setPerPage={setRecordsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                column={column}
                renderColumn={renderColumn}
                tableHeading="All Documents"
                onView={(item) => {
                    window.location.href = `/campus/${item.CampusId}`;
                }}
            />
        </div>
    );
};

export default UploadDocumentsContent;
