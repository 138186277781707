import React from 'react';
import GenericProfileForm from '../../../../Form/GenericProfileForm';

const AddProfessional = ({ onClose, id, scholarId }) => {
    const initialValues = {
        Title: '',
        Organizer: '',
        ISSN: '',
        Volume: null,
        JournalName: '',
        ConferenceName: '',
        Link: '',
        Year: null,
        Date: null,
        UserProfile: scholarId,
        CreatedBy: null,
    };

    const fields = [
        {
            type: 'text',
            field: 'Title',
            label: 'Title',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Organizer',
            label: 'Organizer',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'ISSN',
            label: 'ISSN',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
        {
            type: 'text',
            field: 'Volume',
            label: 'Volume',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
        {
            type: 'text',
            field: 'JournalName',
            label: 'Journal Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
        {
            type: 'text',
            field: 'ConferenceName',
            label: 'Conference Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
        {
            type: 'text',
            field: 'Link',
            label: 'Link',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
        {
            type: 'text',
            field: 'Year',
            label: 'Year',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
        {
            type: 'date',
            field: 'Date',
            label: 'Date',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
        },
    ];

    return (
        <div className="userprofile-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Add Qualification Details</h5>
                    <span>Update your qualification details here</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onClose}
                    src="/images/cross.svg"
                    alt={'Close Modal'}
                />
            </div>

            <GenericProfileForm
                fields={fields}
                id={id}
                initialValues={initialValues}
                onClose={onClose}
                endpoint={'Conference'}
            />
        </div>
    );
};

export default AddProfessional;
