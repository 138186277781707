import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkButton } from '../Buttons/LinkButton';
import SelectInput from '../Inputs/SelectInput';
import { CSVLink } from 'react-csv';

const Heading = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [exportData, setExportData] = useState([]);

    return (
        <div className="dashboard-heading">
            <div>
                <h1>{props.title}</h1>
                {props.description && <span>{props.description}</span>}
            </div>

            <div className="heading-buttons">
                {props.importtext && (
                    <Link className="heading-link" to={props.importonClick}>
                        <img className="import-icon" src={props.importicon} />
                        <img className="plus-icon" src={props.plusicon} />
                        {props.importtext}
                    </Link>
                )}

                {props.linktext && (
                    <LinkButton
                        className={props.className}
                        linktext={props.linktext}
                        icon={props.icon}
                        width={props.width}
                        height={props.height}
                        href={props.href}
                        backgroundcolor={props.backgroundcolor}
                        color={props.color}
                        hover={props.hover}
                        fontsize={props.fontsize}
                        padding={props.padding}
                        onClick={props.onClick}
                    />
                )}
                {props.exporticon && (
                    <CSVLink
                        data={exportData}
                        asyncOnClick={true}
                        onClick={(event, done) => {
                            if (!props.tableRef.current) {
                                return;
                            }
                            let data = props.tableRef.current.exportData();
                            setExportData(data);
                            done(true);
                        }}
                        filename={props.exportFileName || props.title}
                    >
                        
                        <div className={props.importtext ? "export-button":"export-button-link"}>
                            <img
                                className="export-icon"
                                src={props.exporticon}
                            />
                        </div>
                    </CSVLink>
                )}
            </div>
            

            {props.options && (
                <div className="select-dropdown">
                    <SelectInput
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={props.options}
                    />
                </div>
            )}
        </div>
    );
};

export default Heading;
