import _, { isObject } from 'lodash';

let MONTHS = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
};

const renderValue = (item, field) => {
    if (field.render) {
        return field.render(item);
    }
    let result = _.get(item, field.value);
    if (isObject(item[field.value]) && item[field.value].date) {
        result = result.date.split(' ')[0];
    }

    if (
        typeof result === 'string' &&
        result &&
        result.match(/^\d\d\d\d-(\d)?\d-(\d)?\d \d\d:\d\d:\d\d$/g)
    ) {
        const dateParts = result.split(' ')[0].split('-');
        return `${MONTHS[dateParts[1]]} ${dateParts[2]}, ${dateParts[0]}`;
    }
    if (
        typeof result === 'string' &&
        result &&
        result.match(/^\d\d\d\d-(\d)?\d-(\d)?\d$/g)
    ) {
        const dateParts = result.split(' ')[0].split('-');
        return `${MONTHS[dateParts[1]]} ${dateParts[2]}, ${dateParts[0]}`;
    }

    return result;
};
export default renderValue;
