import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import FormHeader from '../../../components/Header/FormHeader';
import FormHeading from '../../../components/Heading/FormHeading';
import { LinkButton } from '../../../components/Buttons/LinkButton';
import Backarrow from '../../../../images/back-arrow.svg';
import { SimpleInput } from '../../../components/Inputs/SimpleInput';
import { Button } from '../../../components/Buttons/Button';
import qs from 'qs';
import Api from '../../../../api/api';

const AddCampus = () => {
    const initialValues = {
        password: '',
        confirmPassword: '',
        currentPassword: '',
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [successMsg, setSuccessMsg] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, SetLoading] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (event) => {
        let i = { ...formValues };
        i[event.target.name] = event.target.value;
        setFormValues(i);
    };

    const handleSignin = (e) => {
        let { token } = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        e.preventDefault();
        let errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }
        SetLoading(true);
        Api.post(
            '/Authentication/login/changePassword',
            {
                CurrentPassword: formValues.currentPassword,
                NewPassword: formValues.password,
            },
            true,
        )
            .then((response) => {
                SetLoading(false);
                alert('Password has been updated.');
            })
            .catch((e) => {
                if (e.Message) {
                    setFormErrors({ error: e.Message });
                } else {
                    setFormErrors({
                        error: 'Something went wrong',
                    });
                }
                SetLoading(false);
            });
    };

    const validate = (values) => {
        setSuccessMsg();
        const errors = {};

        const passwordValue = values.password;

        if (!values.currentPassword) {
            errors.currentPassword = 'Current password is required';
        }

        if (!values.password) {
            errors.password = 'New password is required';
        }
        if (values.password !== values.confirmPassword) {
            errors.password = 'Passwords do not match';
            errors.confirmPassword = 'Passwords do not match';
        }

        return errors;
    };
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <div className="heading-button-mobile">
                    <FormHeader
                        homelink="/dashboard"
                        breadcrumb="Settings"
                        breadcrumbs="Change Password"
                        innerpath="/dashboard"
                    />
                    <FormHeading
                        title="Change Password"
                        description="Change your current password"
                    />
                    <MediaQuery maxWidth={1023}>
                        <div className="back-button">
                            <LinkButton
                                linktext="Back to Campuses"
                                icon={Backarrow}
                                href="/campus"
                                width="204px"
                                height="44px"
                                backgroundcolor="none"
                                color="#5D43A5"
                                fontsize="16px"
                                hover="none"
                                border="none"
                            />
                        </div>
                    </MediaQuery>
                    <div>
                        <form>
                            <div>
                                <div className="settings-container">
                                    <div className="login-input">
                                        <SimpleInput
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            name="currentPassword"
                                            placeholder="Enter your current password"
                                            value={formValues.currentPassword}
                                            onChange={handleChange}
                                            error={formErrors.currentPassword}
                                            label="Current Password*"
                                            onClick={togglePasswordVisibility}
                                            righticon={
                                                showPassword
                                                    ? '../../../images/view.svg'
                                                    : '../../../images/view-hide.svg'
                                            }
                                            successMsg={successMsg}
                                        />
                                    </div>
                                    <div className="login-input">
                                        <SimpleInput
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            name="password"
                                            placeholder="Enter new password"
                                            value={formValues.password}
                                            onChange={handleChange}
                                            error={formErrors.password}
                                            label="New Password*"
                                            onClick={togglePasswordVisibility}
                                            righticon={
                                                showPassword
                                                    ? '../../../images/view.svg'
                                                    : '../../../images/view-hide.svg'
                                            }
                                            successMsg={successMsg}
                                        />
                                    </div>
                                    <div className="login-input">
                                        <SimpleInput
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            name="confirmPassword"
                                            placeholder="Confirm new password"
                                            value={formValues.confirmPassword}
                                            onChange={handleChange}
                                            error={formErrors.confirmPassword}
                                            label="Confirm New Password*"
                                            onClick={togglePasswordVisibility}
                                            righticon={
                                                showPassword
                                                    ? '../../../images/view.svg'
                                                    : '../../../images/view-hide.svg'
                                            }
                                            successMsg={successMsg}
                                        />
                                    </div>

                                    <div className={'error text-danger'}>
                                        {formErrors.error}
                                    </div>

                                    <div className="signin-button">
                                        <Button
                                            onClick={handleSignin}
                                            loading={loading}
                                            hover="#5D43A5"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCampus;
