import { useCallback, useEffect, useState } from 'react';
import Api from '../api/api';

const useProfileListApi = (url, id) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    const refresh = useCallback(() => {
        Api.get(`/UserProfile/${url}/getEntityByUser/${id}`)
            .then((response) => {
                setRows(response);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        loading,
        rows,
        refresh,
    };
};

export default useProfileListApi;
