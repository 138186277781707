import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../Buttons/Button';
import { ExportButton } from '../../../../../Buttons/ExportButton';
import Api from '../../../../../../../api/api';
import SelectInput from '../../../../../Inputs/SelectInput';

const AssignFeePolicy = ({
    users,
    onHide,
    ResearchCenterId,
    refresh,
    CampusId,
}) => {
    const [supervisors, setSupervisors] = useState([]);
    const [coSupervisors, setCoSupervisors] = useState([]);
    const [selectedDeliverables, setSelectedDeliverables] = useState([]);

    const [input, setInput] = useState({
        Supervisor: users[0].Supervisor_id,
        CoSupervisor1: users[0].CoSupervisor1_id,
        CoSupervisor2: users[0].CoSupervisor2_id,
    });

    useEffect(() => {
        console.log({ input });
    }, [input]);

    useEffect(() => {
        Api.get('/Users/Staff/getEntities', { CampusId }).then((response) => {
            setSupervisors(response.Data);
        });
        Api.get('/Users/Staff/getEntities', {
            DRCId: ResearchCenterId,
        }).then((response) => {
            setCoSupervisors(response.Data);
        });
    }, [ResearchCenterId]);

    const submit = () => {
        Api.post(`/Users/Scholar/AssignGuides/${users[0].Id}`, input)
            .then(() => {
                onHide();
                refresh();
            })
            .catch(console.log);
    };

    return (
        <div className="fee-policy-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Assign Supervisors</h5>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onHide}
                    src="/images/cross.svg"
                />
            </div>

            <div className="fee-policy-content">
                <>
                    <div className="assign-fee-policy-content">
                        <div className="assign-fee-policy-amount">
                            <div className="milestone-fees">
                                Select Supervisor
                                <div className="select-supervisors-input">
                                    <SelectInput
                                        options={supervisors.map((user) => {
                                            return {
                                                value: user.id,
                                                label: `${user.FirstName} ${user.LastName} - ${user.EmployeeId}`.trim(),
                                            };
                                        })}
                                        onChange={(value) => {
                                            setInput({
                                                ...input,
                                                Supervisor: value.value,
                                            });
                                        }}
                                        value={input.Supervisor}
                                    />
                                </div>
                            </div>
                            <div className="milestone-fees">
                                <div className="select-supervisors-input-heading">
                                    Select Co-Supervisor 1
                                </div>
                                <div className="select-supervisors-input">
                                    <SelectInput
                                        onChange={(value) => {
                                            setInput({
                                                ...input,
                                                CoSupervisor1: value.value,
                                            });
                                        }}
                                        options={coSupervisors.map((user) => {
                                            return {
                                                value: user.id,
                                                label: `${user.FirstName} ${user.LastName} - ${user.EmployeeId}`.trim(),
                                            };
                                        })}
                                        value={input.CoSupervisor1}
                                    />
                                </div>
                            </div>
                            <div className="milestone-fees">
                                Select Co-Supervisor 2
                                <div className="select-supervisors-input">
                                    <SelectInput
                                        options={coSupervisors.map((user) => {
                                            return {
                                                value: user.id,
                                                label: `${user.FirstName} ${user.LastName} - ${user.EmployeeId}`.trim(),
                                            };
                                        })}
                                        onChange={(value) => {
                                            setInput({
                                                ...input,
                                                CoSupervisor2: value.value,
                                            });
                                        }}
                                        value={input.CoSupervisor2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>

            <div className="bottom-line-assign-fee-policy"></div>

            <div className="cb-buttons-assign-fee-policy">
                <ExportButton
                    width="220px"
                    height="40px"
                    hover="#F9FAFB"
                    border="1px solid #D0D5DD"
                    onClick={onHide}
                >
                    Cancel
                </ExportButton>
                <Button
                    onClick={submit}
                    width="220px"
                    height="40px"
                    padding="0px 20px !important"
                    hover="#5D43A5"
                >
                    Save
                </Button>
            </div>
        </div>
    );
};

export default AssignFeePolicy;
