import React, { useState } from 'react';
import { Button } from '../../../../../../../components/Buttons/Button';
import { ExportButton } from '../../../../../../../components/Buttons/ExportButton';
import Heading from '../../../../../../../components/Heading/Heading';
import CustomCheckbox from '../../../../../../../components/Inputs/CustomCheckbox';
import SelectInput from '../../../../../../../components/Inputs/SelectInput';
import { SimpleInput } from '../../../../../../../components/Inputs/SimpleInput';

const country = [{ value: 'india', label: 'India' }];

const state = [{ value: 'uttar pradesh', label: 'Uttar Pradesh' }];

const AddressContent = () => {
    const [checked, setChecked] = useState(false);

    const initialValues = {
        address: '',
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (event) => {
        let i = { ...formValues };
        i[event.target.name] = event.target.value;
        setFormValues(i);
    };

    const handleSave = (e) => {
        e.preventDefault();
        let errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            handleSave({ ...formValues });
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.address) {
            errors.address = 'Address is required*';
        }
        return errors;
    };

    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title="Address"
                    description="Update your address details here."
                />
            </div>

            <div className="personal-info">
                <div className="address-field-permanent">
                    <h5>Permanent Address</h5>
                </div>
                <div className="personal-info-data">
                    <div className="personal-name-input">
                        <SimpleInput
                            placeholder="Enter your address"
                            label="Address"
                            value={formValues.address}
                            onChange={handleChange}
                            error={formErrors.address}
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <div className="personal-info-about">Country</div>
                        <SelectInput
                            options={country}
                            placeholder="Select your country"
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your pincode"
                            label="Pincode"
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your city"
                            label="City"
                        />
                    </div>

                    <div className="personal-name">
                        <div className="personal-info-about">State</div>
                        <SelectInput
                            options={state}
                            placeholder="Select your state"
                        />
                    </div>
                </div>

                <div className="address-field-communication">
                    <h5>Communication Address</h5>
                </div>

                <div className="address-checkbox">
                    <CustomCheckbox
                        onChange={() => setChecked(!checked)}
                        isChecked={checked}
                    />
                    <span>Same as Permanent Address</span>
                </div>

                <div className="user-personal-info-data">
                    <div className="personal-name-input">
                        <SimpleInput
                            placeholder="Enter your address"
                            label="Address"
                            value={formValues.address}
                            onChange={handleChange}
                            error={formErrors.address}
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <div className="personal-info-about">Country</div>
                        <SelectInput
                            options={country}
                            placeholder="Select your country"
                        />
                    </div>

                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your pincode"
                            label="Pincode"
                        />
                    </div>
                </div>

                <div className="personal-info-data">
                    <div className="personal-name">
                        <SimpleInput
                            placeholder="Enter your city"
                            label="City"
                        />
                    </div>

                    <div className="personal-name">
                        <div className="personal-info-about">State</div>
                        <SelectInput
                            options={state}
                            placeholder="Select your state"
                        />
                    </div>
                </div>

                <div className="bottom-line-personal-info"></div>

                <div className="cb-buttons-userprofile">
                    <ExportButton hover="#F9FAFB" border="none">
                        Skip
                    </ExportButton>
                    <Button
                        width="auto"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                        onClick={handleSave}
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddressContent;
