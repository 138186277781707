import React, { useState } from 'react';
import { Button } from '../../../../Buttons/Button';
import { ExportButton } from '../../../../Buttons/ExportButton';
import SelectInput from '../../../../Inputs/SelectInput';
import { SimpleInput } from '../../../../Inputs/SimpleInput';
import Calendar from '../../../../../../images/calendar.svg';

const options = [
    { value: 'ongoing', label: 'Ongoing' },
    { value: 'completed', label: 'Completed' },
];

const AddPiorScholarInformation = (props) => {
    const initialValues = {
        firstname: '',
        lastname: '',
        institute: '',
        discipline: '',
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (event) => {
        let i = { ...formValues };
        i[event.target.name] = event.target.value;
        setFormValues(i);
    };

    const handleSave = (e) => {
        e.preventDefault();
        let errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            handleSave({ ...formValues });
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.firstname) {
            errors.firstname = 'First Name is required*';
        }

        if (!values.lastname) {
            errors.lastname = 'Last Name is required*';
        }

        if (!values.institute) {
            errors.institute = 'Institute is  required*';
        }

        if (!values.discipline) {
            errors.discipline = 'Discipline is required*';
        }
        return errors;
    };
    return (
        <div className="userprofile-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Add Pior ScholarInformation</h5>
                    <span>
                        Update your pior scholar information details here
                    </span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={props.onClose}
                    src="/images/cross.svg"
                />
            </div>

            <div className="invite-supervisor-content">
                <div className="invite-supervisor">
                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter your first name"
                                label="First Name*"
                                value={formValues.firstname}
                                onChange={handleChange}
                                error={formErrors.firstname}
                            />
                        </div>
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter last name"
                                label="Last Name*"
                                value={formValues.lastname}
                                onChange={handleChange}
                                error={formErrors.lastname}
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="patent-issue-input">
                            <SimpleInput
                                placeholder="Enter institute"
                                label="Institute*"
                                value={formValues.institute}
                                onChange={handleChange}
                                error={formErrors.institute}
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter discipline"
                                label="Discipline*"
                                value={formValues.discipline}
                                onChange={handleChange}
                                error={formErrors.discipline}
                            />
                        </div>
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter completion date"
                                label="Completion Date"
                                lefticon={Calendar}
                            />
                        </div>
                    </div>

                    <div className="invite-supervisor-input">
                        <div className="supervisor-name">
                            <div className="personal-info-about">
                                Previous Experience Status
                            </div>
                            <SelectInput
                                placeholder="Select status"
                                label="Previous Experience Status"
                                options={options}
                            />
                        </div>
                        <div className="supervisor-name">
                            <SimpleInput
                                placeholder="Enter estimated time"
                                label="Estimated Time"
                            />
                        </div>
                    </div>
                </div>

                <div className="cb-buttons-userprofile">
                    <ExportButton hover="#F9FAFB" border="1px solid #D0D5DD">
                        Cancel
                    </ExportButton>
                    <Button
                        width="auto"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddPiorScholarInformation;
