import React from 'react';
import MediaQuery from 'react-responsive';
import { LinkButton } from '../../../../../../../components/Buttons/LinkButton';
import FormHeader from '../../../../../../../components/Header/FormHeader';
import FormHeading from '../../../../../../../components/Heading/FormHeading';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import AssignDeliverableContent from './AssignDeliverableContent';

const AssignDeliverable = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <div className="heading-button-mobile">
                    <FormHeader
                        homelink="/dashboard"
                        breadcrumb="Sub Deliverables Assignment"
                        breadcrumbs="Assign Sub Deliverable"
                        innerpath="/assign-deliverables"
                    />
                    <FormHeading
                        title="Assign Sub Deliverable"
                        description="Enter the following details to assign deliverables"
                    />
                    <MediaQuery maxWidth={1023}>
                        <div className="back-button">
                            <LinkButton
                                linktext="Back to Sub Deliverables"
                                href="/sub-deliverable"
                                width="204px"
                                height="44px"
                                backgroundcolor="none"
                                color="#5D43A5"
                                fontsize="16px"
                                hover="none"
                            />
                        </div>
                    </MediaQuery>
                </div>
                <AssignDeliverableContent />
            </div>
        </div>
    );
};

export default AssignDeliverable;
