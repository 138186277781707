import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './view/auth/Login';
import Dashboard from './view/main/Dashboard/Dashboard';
import AddCampus from './view/main/Dashboard/Add Campus/AddCampus';
import AddClusters from './view/main/Dashboard/Clusters/Subcomonents/Add Clusters/AddClusters';
import ClustersList from './view/main/Dashboard/Clusters/ClustersList';
import ViewCampus from './view/main/Dashboard/View Campus/ViewCampus';
import ResearchCentreList from './view/main/Dashboard/Clusters/Research Centre/Research Centre List/ResearchCentreList';
import AddResearchCentre from './view/main/Dashboard/Clusters/Research Centre/Research Centre List/Subcomponents/Add Research Centre/AddResearchCentre';
import ProgramsList from './view/main/Dashboard/Clusters/Research Centre/Research Centre List/Programs/Program List/ProgramsList';
import AddProgram from './view/main/Dashboard/Clusters/Research Centre/Research Centre List/Programs/Program List/Subcomponents/Add Program/AddProgram';
import AddDiscipline from './view/main/Dashboard/Clusters/Research Centre/Research Centre List/Programs/Disciplines/Disciplines List/Subcomponents/AddDiscipline/AddDiscipline';
import DisciplinesList from './view/main/Dashboard/Clusters/Research Centre/Research Centre List/Programs/Disciplines/Disciplines List/DisciplinesList';
import DepartmentList from './view/main/Dashboard/Departments/DepartmentList';
import AddDepartment from './view/main/Dashboard/Departments/Subcomponents/Add Departement/AddDepartment';
import AcademicList from './view/main/Dashboard/Academic Years/AcademicList';
import AddAcademicYear from './view/main/Dashboard/Academic Years/Subcomponents/Add Academic Year/AddAcademicYear';
import BatchesList from './view/main/Dashboard/Batches/BatchesList';
import AddBatch from './view/main/Dashboard/Batches/Subcomponents/AddBatches/AddBatch';
import ScholarsList from './view/main/Dashboard/Users/Scholars/ScholarsList';
import AddScholar from './view/main/Dashboard/Users/Scholars/Subcomponents/Add Scholars/AddScholar';
import Personalnfo from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Personal Info/Personalnfo';
import Address from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Address/Address';
import UploadDocuments from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Upload Documents/UploadDocuments';
import Qualification from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Qualification/Qualification';
import Project from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Projects/Project';
import BankDetail from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Bank Details/BankDetail';
import PatentDetail from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Patent Details/PatentDetail';
import ConferenceDetail from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Conference Details/ConferenceDetail';
import BookChapterDetail from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Book Chapter Details/BookChapterDetail';
import AwardDetail from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Award Details/AwardDetail';
import BookDetail from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Book Details/BookDetail';
import PiorScholarInformation from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Pior Scholar Information/PiorScholarInformation';
import SocialSite from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Social Sites/SocialSite';
import JournalDetail from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Journal Details/JournalDetail';
import PublicationDetail from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Publications Details/PublicationDetail';
import ProfessionalDetail from './view/main/Dashboard/Users/Scholars/Subcomponents/UserProfile/Professional Details/ProfessionalDetail';
import SupervisorList from './view/main/Dashboard/Users/Supervisor/SupervisorList';
import DRCFacultyList from './view/main/Dashboard/Users/DRC Faculty/DRCFacultyList';
import AddDRCFaculty from './view/main/Dashboard/Users/DRC Faculty/Subcomponents/Add DRC Faculty/AddDRCFaculty';
import AddSupervisor from './view/main/Dashboard/Users/Supervisor/Subcomponents/Add Supervisors/AddSupervisor';
import AddProfDefault from './view/components/Default Page/User Profile Default Pages/Subcomponents/Add Prof Default/AddProfDefault';
import AddQualificationDefault from './view/components/Default Page/User Profile Default Pages/Subcomponents/Add Qualification Default/AddQualificationDefault';
import AddProjectDefault from './view/components/Default Page/User Profile Default Pages/Subcomponents/Add Project Default/AddProjectDefault';
import AddPatentDefault from './view/components/Default Page/User Profile Default Pages/Subcomponents/Add Patent Default/AddPatentDefault';
import AddConferenceDefault from './view/components/Default Page/User Profile Default Pages/Subcomponents/Add Conference Default/AddConferenceDefault';
import AddAwardDefault from './view/components/Default Page/User Profile Default Pages/Subcomponents/Add Award Default/AddAwardDefault';
import AddBookChapterDefault from './view/components/Default Page/User Profile Default Pages/Subcomponents/Add Book Chapter Default/AddBookChapterDefault';
import AddBookDefault from './view/components/Default Page/User Profile Default Pages/Subcomponents/Add Book Default/AddBookDefault';
import AddPiorScholarInformationDefault from './view/components/Default Page/User Profile Default Pages/Subcomponents/Add Pior Scholar Information Default/AddPiorScholarInformationDefault';
import AddJournalDefault from './view/components/Default Page/User Profile Default Pages/Subcomponents/Add Journal Default/AddJournalDefault';
import AddPublicationDefault from './view/components/Default Page/User Profile Default Pages/Subcomponents/Add Publication Default/AddPublicationDefault';
import ImportSupervisor from './view/main/Dashboard/Users/Supervisor/Subcomponents/Import Supervisor/ImportSupervisor';
import ImportScholar from './view/main/Dashboard/Users/Scholars/Subcomponents/Import Scholar/ImportScholar';
import ImportDRCFaculty from './view/main/Dashboard/Users/DRC Faculty/Subcomponents/Import DRC Faculty/ImportDRCFaculty';
import InviteSupervisor from './view/main/Dashboard/Users/Supervisor/Subcomponents/Invite Supervisor/InviteSupervisor';
import ScholarDefault from './view/components/Default Page/Scholar Default Page/ScholarDefault';
import SupervisorDefault from './view/components/Default Page/Supervisor Default Page/SupervisorDefault';
import DRCFacultyDefault from './view/components/Default Page/DRC Faculty Page/DRCFacultyDefault';
import MilestoneList from './view/main/Dashboard/Academic Years/Subcomponents/Milestones/MilestoneList';
import AddMilestone from './view/main/Dashboard/Academic Years/Subcomponents/Milestones/Subcomponents/Add Milestone/AddMilestone';
import DeliverableList from './view/main/Dashboard/Academic Years/Subcomponents/Deliverables/DeliverableList';
import AddDeliverable from './view/main/Dashboard/Academic Years/Subcomponents/Deliverables/Subcomponents/Add Deliverables/AddDeliverable';
import InviteSupervisorDefault from './view/components/Default Page/Supervisor Default Page/Subcomponents/Invite Superviosr Default/InviteSupervisorDefault';
import InviteSupervisorList from './view/main/Dashboard/Users/Supervisor/Subcomponents/Invite Supervisor/InviteSupervisorList';
import FeePolicyList from './view/main/Dashboard/Fee/Subcomponnets/Fee Policy/FeePolicyList';
import AddFeePolicy from './view/main/Dashboard/Fee/Subcomponnets/Fee Policy/Subcomponents/Add Fee Policy/AddFeePolicy';
import AssignFeePolicyList from './view/main/Dashboard/Fee/Subcomponnets/Assign Fee Policy/AssignFeePolicyList';
import AssignedFeePolicy from './view/main/Dashboard/Fee/Subcomponnets/Assign Fee Policy/Subcomponents/Assigned Fee Policy/AssignedFeePolicy';
import ViewDepartment from './view/main/Dashboard/Departments/ViewDepartment';
import ViewCluster from './view/main/Dashboard/Clusters/ViewCluster';
import ViewResearhCentre from './view/main/Dashboard/Clusters/ViewResearhCentre';
import ViewAcademicYear from './view/main/Dashboard/Academic Years/ViewAcademicYear';
import ViewBatch from './view/main/Dashboard/Academic Years/ViewBatch';
import ViewProgram from './view/main/Dashboard/Academic Years/ViewProgram';
import ViewDiscipline from './view/main/Dashboard/Academic Years/ViewDiscipline';
import ViewMilestone from './view/main/Dashboard/Academic Years/ViewMilestone';
import ViewDeliverable from './view/main/Dashboard/Academic Years/ViewDeliverable';
import ViewScholar from './view/main/Dashboard/Academic Years/ViewScholar';
import ViewDRCFaculty from './view/main/Dashboard/Academic Years/ViewDRCFaculty';
import ViewSupervisor from './view/main/Dashboard/Academic Years/ViewSupervisor';
import ViewFeePolicy from './view/main/Dashboard/Academic Years/ViewFeePolicy';
import AssignDeliverableList from './view/main/Dashboard/Fee/Subcomponnets/Assign Fee Policy/AssignDeliverableList';
import AssignSupervisorList from './view/main/Dashboard/Fee/Subcomponnets/Assign Fee Policy/AssignSupervisorList';
import PromoteScholarList from './view/main/Dashboard/Fee/Subcomponnets/Assign Fee Policy/PromoteScholarList';
import Forgot from './view/auth/Forgot';
import Reset from './view/auth/Reset';
import Home from './view/auth/Home';
import AssignDeliverable from './view/main/Dashboard/Academic Years/Subcomponents/Deliverables/Subcomponents/Assign Deliverables/AssignDeliverable';
import ChangePassword from './view/main/Dashboard/Add Campus/ChangePassword';

function App() {
    return (
        <div>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/reset" element={<Reset />} />
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/campus" element={<Dashboard />} />
                <Route path="/campus/:id" element={<ViewCampus />} />
                <Route path="/campus/:id/edit" element={<AddCampus />} />
                <Route path="/campus/new" element={<AddCampus />} />
                <Route path="/registry" element={<ClustersList />} />
                <Route path="/registry/new" element={<AddClusters />} />
                <Route path="/registry/:id/edit" element={<AddClusters />} />
                <Route path="/registry/:id" element={<ViewCluster />} />
                <Route path="/discipline" element={<ResearchCentreList />} />
                <Route path="/discipline/new" element={<AddResearchCentre />} />
                <Route
                    path="/discipline/:id/edit"
                    element={<AddResearchCentre />}
                />
                <Route path="/discipline/:id" element={<ViewResearhCentre />} />
                <Route path="/program" element={<ProgramsList />} />
                <Route path="/program/new" element={<AddProgram />} />
                <Route path="/program/:id/edit" element={<AddProgram />} />
                <Route path="/program/:id/view" element={<ViewProgram />} />
                <Route path="/specialization" element={<DisciplinesList />} />
                <Route path="/specialization/new" element={<AddDiscipline />} />
                <Route
                    path="/specialization/:id/edit"
                    element={<AddDiscipline />}
                />
                <Route
                    path="/specialization/:id/view"
                    element={<ViewDiscipline />}
                />
                <Route path="/department" element={<DepartmentList />} />
                <Route path="/department/new" element={<AddDepartment />} />
                <Route
                    path="/department/:id/edit"
                    element={<AddDepartment />}
                />
                <Route path="/department/:id" element={<ViewDepartment />} />
                <Route path="/academic-year" element={<AcademicList />} />
                <Route
                    path="/academic-year/new"
                    element={<AddAcademicYear />}
                />
                <Route
                    path="/academic-year/:id/edit"
                    element={<AddAcademicYear />}
                />
                <Route
                    path="/academic-year/:id/view"
                    element={<ViewAcademicYear />}
                />
                <Route path="/batch" element={<BatchesList />} />
                <Route path="/batch/new" element={<AddBatch />} />
                <Route path="/batch/:id/edit" element={<AddBatch />} />
                <Route path="/batch/:id/view" element={<ViewBatch />} />
                <Route path="/scholar" element={<ScholarsList />} />
                <Route path="/scholar/new" element={<AddScholar />} />
                <Route path="/scholar/:id/edit" element={<AddScholar />} />
                <Route path="/scholar/:id/view" element={<ViewScholar />} />
                <Route path="/staff" element={<DRCFacultyList />} />
                <Route path="/staff/new" element={<AddDRCFaculty />} />
                <Route
                    path="/staff/:id/edit"
                    element={<AddDRCFaculty />}
                />
                <Route
                    path="/staff/:id/view"
                    element={<ViewDRCFaculty />}
                />
                <Route path="/supervisor" element={<SupervisorList />} />
                <Route path="/supervisor/new" element={<AddSupervisor />} />
                <Route
                    path="/supervisor/:id/edit"
                    element={<AddSupervisor />}
                />
                <Route
                    path="/supervisor/:id/view"
                    element={<ViewSupervisor />}
                />
                <Route
                    path="/scholar/:scholarId/personal"
                    element={<Personalnfo />}
                />
                <Route
                    path="/scholar/:scholarId/address"
                    element={<Address />}
                />
                <Route
                    path="/scholar/:scholarId/bank"
                    element={<BankDetail />}
                />
                <Route
                    path="/scholar/:scholarId/documents"
                    element={<UploadDocuments />}
                />
                <Route
                    path="/scholar/:scholarId/qualifications"
                    element={<Qualification />}
                />
                <Route
                    path="/scholar/:scholarId/projects"
                    element={<Project />}
                />
                <Route
                    path="/scholar/:scholarId/patents"
                    element={<PatentDetail />}
                />
                <Route
                    path="/scholar/:scholarId/conferences"
                    element={<ConferenceDetail />}
                />
                <Route
                    path="/scholar/:scholarId/awards"
                    element={<AwardDetail />}
                />
                <Route
                    path="/scholar/:scholarId/book-chapters"
                    element={<BookChapterDetail />}
                />
                <Route
                    path="/scholar/:scholarId/books"
                    element={<BookDetail />}
                />
                <Route
                    path="/scholar/:scholarId/scholars"
                    element={<PiorScholarInformation />}
                />
                <Route
                    path="/scholar/:scholarId/social"
                    element={<SocialSite />}
                />
                <Route
                    path="/scholar/:scholarId/journals"
                    element={<JournalDetail />}
                />
                <Route
                    path="/scholar/:scholarId/publications"
                    element={<PublicationDetail />}
                />
                <Route
                    path="/scholar/:scholarId/professional"
                    element={<ProfessionalDetail />}
                />
                <Route
                    path="/professional-default/new"
                    element={<AddProfDefault />}
                />
                <Route
                    path="/scholar/:scholarId/qualifications"
                    element={<AddQualificationDefault />}
                />
                <Route path="/project/new" element={<AddProjectDefault />} />
                <Route path="/patent/new" element={<AddPatentDefault />} />
                <Route
                    path="/conference/new"
                    element={<AddConferenceDefault />}
                />
                <Route path="/award/new" element={<AddAwardDefault />} />
                <Route
                    path="/book-chapter/new"
                    element={<AddBookChapterDefault />}
                />
                <Route path="/book/new" element={<AddBookDefault />} />
                <Route
                    path="/pior-scholar-information/new"
                    element={<AddPiorScholarInformationDefault />}
                />
                <Route path="/journal/new" element={<AddJournalDefault />} />
                <Route
                    path="/publication/new"
                    element={<AddPublicationDefault />}
                />
                <Route
                    path="/supervisor/import"
                    element={<ImportSupervisor />}
                />
                <Route path="/scholar/import" element={<ImportScholar />} />
                <Route
                    path="/import-staff"
                    element={<ImportDRCFaculty />}
                />
                <Route
                    path="/invite-Supervisor"
                    element={<InviteSupervisorList />}
                />
                <Route
                    path="/invite-Supervisor/new"
                    element={<InviteSupervisor />}
                />
                <Route
                    path="/promote-scholar"
                    element={<PromoteScholarList />}
                />
                <Route path="/default-scholar" element={<ScholarDefault />} />
                <Route
                    path="/default-Supervisor"
                    element={<SupervisorDefault />}
                />
                <Route
                    path="/default-staff"
                    element={<DRCFacultyDefault />}
                />
                <Route
                    path="/default-invite-Supervisor"
                    element={<InviteSupervisorDefault />}
                />
                <Route path="/deliverable" element={<MilestoneList />} />
                <Route path="/deliverable/new" element={<AddMilestone />} />
                <Route
                    path="/deliverable/:id/edit"
                    element={<AddMilestone />}
                />
                <Route
                    path="/deliverable/:id/view"
                    element={<ViewMilestone />}
                />
                <Route path="/sub-deliverable" element={<DeliverableList />} />
                <Route
                    path="/sub-deliverable/new"
                    element={<AddDeliverable />}
                />
                <Route
                    path="/sub-deliverable/:id/edit"
                    element={<AddDeliverable />}
                />
                <Route
                    path="/sub-deliverable/:id/view"
                    element={<ViewDeliverable />}
                />
                <Route path="/fee-policy" element={<FeePolicyList />} />
                <Route path="/fee-policy/new" element={<AddFeePolicy />} />
                <Route path="/fee-policy/:id/edit" element={<AddFeePolicy />} />
                <Route
                    path="/fee-policy/:id/view"
                    element={<ViewFeePolicy />}
                />
                <Route
                    path="/assign-fee-policy"
                    element={<AssignFeePolicyList />}
                />
                <Route
                    path="/assign-deliverables"
                    element={<AssignDeliverableList />}
                />

                <Route
                    path="/scholar/:id/deliverables"
                    element={<AssignDeliverable />}
                />
                <Route
                    path="/assign-supervisors"
                    element={<AssignSupervisorList />}
                />
                <Route
                    path="/scholar/:id/fee"
                    element={<AssignedFeePolicy />}
                />

                <Route path="/settings/password" element={<ChangePassword />} />
            </Routes>
        </div>
    );
}

export default App;
